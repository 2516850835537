import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const SearchFormDesk = () => {
    const [cities, setCities] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiToken = process.env.REACT_APP_API_TOKEN;

    const [selectedSourceCity, setSelectedSourceCity] = useState(null);
    const [filteredSourceCities, setFilteredSourceCities] = useState([]);

    const [selectedDestCity, setSelectedDestCity] = useState(null);
    const [filteredDestCities, setFilteredDestCities] = useState([]);

    const [selectedDate, setSelectedDate] = useState(new Date());

    const history = useNavigate();
    const destCityRef = useRef(null);

    const handleSourceCityChange = (event, newValue) => {
        setSelectedSourceCity(newValue);
    };

    const handleDestCityChange = (event, newValue) => {
        setSelectedDestCity(newValue);
    };

    const handleSourceCitySearch = (event, value) => {
        const searchTerm = value.trim().toLowerCase();
        const filteredOptions = cities.filter((city) =>
        city.city_name.toLowerCase().includes(searchTerm)
        );
        setFilteredSourceCities(filteredOptions);
    };

    const handleDestCitySearch = (event, value) => {
        const searchTerm = value.trim().toLowerCase();
        const filteredOptions = cities.filter((city) =>
          city.city_name.toLowerCase().includes(searchTerm)
        );
        setFilteredDestCities(filteredOptions);
    };
    
    const getOptionLabel = (option) => option.city_name;

    /* const handleBusSearchForm = (e) => {
        e.preventDefault();
        // Perform the search logic or any other action
        console.log("From:", selectedSourceCity);
        console.log("To:", selectedDestCity);
        console.log("Journey Date:", selectedDate);
    
        // Generate the slug using selected values
        const slug = `/bus-list/${selectedSourceCity.city_name.toLowerCase()}-to-${selectedDestCity.city_name.toLowerCase()}/${selectedDate.getDate()}-${selectedDate.getMonth() + 1}-${selectedDate.getFullYear()}`;
    
        // Redirect to the desired page
        history(slug);
    };
 */

    const handleBusSearchForm = (e) => {
        e.preventDefault();
        //console.log("From:", selectedSourceCity);
        if(selectedSourceCity==null){
            document.getElementById('source_fld').focus();
            return false;
        }else if(selectedDestCity==null){
            document.getElementById('destination_fld').focus();
            return false;
        }else if(selectedDate==null){
            document.getElementById('entryDate').focus();
            return false;
        }
        const sourceSlug = selectedSourceCity.city_name; // Get the city slug for source city
        const destSlug = selectedDestCity.city_name; // Get the city slug for destination city
        const dateSlug = formatDate(selectedDate); // Get the formatted date slug
        
        const slug = `bus/${sourceSlug}-to-${destSlug}/${dateSlug}`; // Combine the slugs
    
        history(slug); // Navigate to the new page with the generated slug
    };
    
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        // Fetch cities list from API endpoint using Axios
        axios.get(apiUrl+'/get-cities-list', { 
            headers: { 
                'YESBUS_API_KEY': apiKey,
                Authorization: `Bearer ${apiToken}`,
            }
        })
        .then((response) => {
            setCities(response.data.data);
            setFilteredSourceCities(response.data.data);
            setFilteredDestCities(response.data.data);
        })
        .catch((error) => {
            console.error('Error fetching cities:', error);
        });
    }, []);

    useEffect(()=>{
        if(selectedSourceCity){
            document.getElementById('destination_fld').focus();
            //destCityRef.current.querySelector('input').focus();
        }
    },[selectedSourceCity]);
    
    return (
        <>
            <form action="">
                <div className="row justify-content-md-center">
                    <div className="hero-banner-inner-srchbus">
                    <div className="row no-gutters">
                        <div className="col-md-10">
                        <div className="row no-gutters">
                            <div className="col-md-4 pr-1">
                            <label><b>From</b></label>
                            <div className="input-group mb-3 d-block">
                                <div className="input-group-prepend">
                                <span id="basic-addon1" className="input-group-text">
                                    <img src="/images/Icon-metro-location_1.svg" />
                                </span>
                                <Autocomplete
                                options={filteredSourceCities}
                                value={selectedSourceCity}
                                onChange={handleSourceCityChange}
                                onInputChange={handleSourceCitySearch}
                                getOptionLabel={getOptionLabel}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                    <input {...params.inputProps} placeholder="Enter Source" id="source_fld" className="form-control pl-0" />
                                    </div>
                                )}
                                renderOption={(props, option) => (
                                    <li key={option.id} {...props}>{option.city_name}</li>
                                )}
                                />
                                </div>
                            </div>
                            </div>
                            <div className="col-md-4 pr-1 to_dest_block">
                            <label><b>To</b></label>
                            {/* <div className="swap-srch cursor">
                                <img src="/images/swap.svg" />
                            </div> */}
                            <div className="input-group mb-3 d-block">
                                <div className="input-group-prepend">
                                <span id="basic-addon2" className="input-group-text pl-3 pr-3">
                                    <img src="/images/Icon-metro-location_1.svg" />
                                </span>
                                <Autocomplete
                                options={filteredDestCities}
                                value={selectedDestCity}
                                onChange={handleDestCityChange}
                                onInputChange={handleDestCitySearch}
                                getOptionLabel={getOptionLabel}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                    <input {...params.inputProps} id="destination_fld" placeholder="Enter Destination" className="form-control pl-0" />
                                    </div>
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>{option.city_name}</li>
                                )}
                                />
                                </div>
                            </div>
                            </div>
                            <div className="col-md-4 date_inp">
                            <label><b>Date of Journey</b></label>
                            <div className="input-group mb-3 journy_date_div">
                                <div className="input-group-prepend">
                                    <span id="basic-addon3" className="input-group-text calendar">
                                        <img src="/images/Icon-metro-calendar.svg" />
                                    </span>
                                </div>
                                <DatePicker id="entryDate" className="form-control calendar" placeholder="Journey Date" selected={selectedDate} onChange={(date) => setSelectedDate(date)} dateFormat="dd/MM/yyyy" />
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-2">
                        <label style={{opacity:"0"}}><b>Search</b></label>
                        <div className="bus_search">
                            <button type="submit" className="btn bus_search_hm" onClick={handleBusSearchForm}>Search</button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default SearchFormDesk
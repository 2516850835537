import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun } from '@fortawesome/free-solid-svg-icons';
import { faMugHot } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faMoon } from '@fortawesome/free-solid-svg-icons'; */
import { useFilterContext } from '../../context/FilterContext';
import axios from "axios";

const BusFilter = (props) => {
    const {
        filters: { text, busTypes },
        updateFilterValue,
        updateBusType,
        filter_buses,
        clearFilters,
    } = useFilterContext();
    //console.log(filter_buses);
    const [busTypesData, setBusTypes] = useState([]);
    const getBuseTypes = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const apiKey = process.env.REACT_APP_API_KEY;
        const apiToken = process.env.REACT_APP_API_TOKEN;
        try {
            const res =  await axios.get(apiUrl+'/master/bus-types', { 
                headers: {
                    'YESBUS_API_KEY': apiKey,
                    Authorization: `Bearer ${apiToken}`,
                }
            });
            const busesType = await res.data;
            if(busesType.status===200){
                setBusTypes(busesType.data);
            }else{
                
            }
        } catch (error) {
            
        }
    }
    useEffect(() => {
        getBuseTypes();
    }, []);

    /* const [selectedDepartureTimes, setSelectedDepartureTimes] = useState([]);
    const [selectedBusTypes, setSelectedBusTypes] = useState([]);
    const [selectedArrivalTimes, setSelectedArrivalTimes] = useState([]);
    const handleDepartureTimeChange = (time) => {
        if (selectedDepartureTimes.includes(time)) {
            setSelectedDepartureTimes(selectedDepartureTimes.filter(item => item !== time));
        } else {
            setSelectedDepartureTimes([...selectedDepartureTimes, time]);
        }
        filterBusList();
    };
    const handleBusTypeChange = (type) => {
        if (selectedBusTypes.includes(type)) {
            setSelectedBusTypes(selectedBusTypes.filter(item => item !== type));
        } else {
            setSelectedBusTypes([...selectedBusTypes, type]);
        }
        filterBusList();
    };
    const handleArrivalTimeChange = (time) => {
        if (selectedArrivalTimes.includes(time)) {
          setSelectedArrivalTimes(selectedArrivalTimes.filter(item => item !== time));
        } else {
          setSelectedArrivalTimes([...selectedArrivalTimes, time]);
        }
        filterBusList();
    };
    const filterBusList = () => {
        console.log(selectedDepartureTimes);
        // const filteredBuses = props.busList.filter(bus => {
        //   const matchesDepartureTime = selectedDepartureTimes.length === 0 || selectedDepartureTimes.includes(bus.departureTime);
        //   const matchesBusType = selectedBusTypes.length === 0 || selectedBusTypes.includes(bus.busType);
        //   const matchesArrivalTime = selectedArrivalTimes.length === 0 || selectedArrivalTimes.includes(bus.arrivalTime);
    
        //   return matchesDepartureTime && matchesBusType && matchesArrivalTime;
        // });
        // // Update the filtered bus list in your parent component state
        // props.setFilteredBuses(filteredBuses);
    }; */
    return (
        <>
            <FilterContainer className='sidebar_filter_sec'>
                {/* <div className="clear_filter_sec">
                    <span>Clear Filter</span>
                </div> */}
                <div className="sticky_filter_section">
                    
                    <div className="filter_title_sec">
                        <div className="filter_tile f-bold">
                        FILTERS
                        <h6 className='filter_tile_label'>{filter_buses.length} Bus(es)</h6>
                        </div>
                        {/* <div className="total_bus_found">{filter_buses.length} Bus(es)</div> */}
                        <div className="clear_filter_sec" onClick={clearFilters}>
                            <span>Clear Filter</span>
                        </div>
                    </div>
                    <div className="filter_details_sec">
                        <input type="text" className='search_input' name="text" onChange={updateFilterValue} placeholder='Seach Bus Name here..' />
                        <div className="filter_sec_head_title f-bold">DEPARTURE TIME</div>
                        <ul className="filter_sub_sec">
                            <li className="checkbox">
                                <input
                                    id="dtBefore 6 am"
                                    name="departure_filter"
                                    value="depart_before_6am"
                                    type="radio"
                                    onChange={updateFilterValue}
                                />
                                <label htmlFor="dtBefore 6 am" className="filter_sub_sec_title"></label>
                                {/* <span><FontAwesomeIcon icon={faMugHot} /></span> */}
                                <label htmlFor="dtBefore 6 am" className="cbox-label" title="Before 6 am">Before 6 am 
                                {/* (<span id="time_1">0</span>) */}
                                </label>
                            </li>
                            <li className="checkbox">
                                <input
                                    id="dt6 am to 12 pm"
                                    name="departure_filter"
                                    value="depart_6am_to_12pm"
                                    type="radio"
                                    onChange={updateFilterValue}
                                />
                                <label htmlFor="dt6 am to 12 pm" className="filter_sub_sec_title"></label>
                                {/* <span><FontAwesomeIcon icon={faSun} /></span> */}
                                <label htmlFor="dt6 am to 12 pm" className="cbox-label" title="6 am to 12 pm">6 am to 12 pm 
                                {/* (<span id="time_2">1</span>) */}
                                </label>
                            </li>
                            <li className="checkbox">
                                <input
                                    id="dt12 pm to 6 pm"
                                    name="departure_filter"
                                    value="depart_12pm_to_6pm"
                                    type="radio"
                                    onChange={updateFilterValue}
                                />
                                <label htmlFor="dt12 pm to 6 pm" className="filter_sub_sec_title"></label>
                                {/* <span><FontAwesomeIcon icon={faClock} /></span> */}
                                <label htmlFor="dt12 pm to 6 pm" className="cbox-label" title="12 pm to 6 pm">12 pm to 6 pm 
                                {/* (<span id="time_3">0</span>) */}
                                </label>
                            </li>
                            <li className="checkbox">
                                <input
                                    id="dtAfter 6 pm"
                                    name="departure_filter"
                                    value="depart_after_6pm"
                                    type="radio"
                                    onChange={updateFilterValue}
                                />
                                <label htmlFor="dtAfter 6 pm" className="filter_sub_sec_title"></label>
                                {/* <span><FontAwesomeIcon icon={faMoon} /></span> */}
                                <label htmlFor="dtAfter 6 pm" className="cbox-label" title="After 6 pm">After 6 pm 
                                {/* (<span id="time_4">0</span>) */}
                                </label>
                            </li>
                        </ul>
                        <div className="filter_sec_head_title f-bold">BUS TYPES</div>
                        <ul className="filter_sub_sec">
                            {busTypesData.length > 0 &&
                                busTypesData.map((bustyp, index) => (
                                <li className="checkbox" key={index}>
                                    <input
                                    id={`bt_${bustyp.type_name}`}
                                    name={bustyp.type_name}
                                    value={bustyp.type_name}
                                    type="checkbox"
                                    onChange={updateBusType}
                                    />
                                    <label htmlFor={`bt_${bustyp.type_name}`} className="filter_sub_sec_title"></label>
                                    <label htmlFor={`bt_${bustyp.type_name}`} className="cbox-label" title={bustyp.type_name}>
                                    {bustyp.type_name} 
                                    {/* (<span id={bustyp.type_name}>0</span>) */}
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <div className="filter_sec_head_title f-bold">ARRIVAL TIME</div>
                        <ul className="filter_sub_sec">
                            <li data-value="" className="checkbox">
                                <input
                                    id="atBefore 6 am"
                                    name="arrival_filter"
                                    value="arrival_before_6pm"
                                    type="radio"
                                    onChange={updateFilterValue}
                                />
                                <label htmlFor="atBefore 6 am" className="filter_sub_sec_title"></label>
                                <span><i className="icon fl icon-morning"></i></span>
                                <label htmlFor="atBefore 6 am" className="cbox-label" title="Before 6 am">Before 6 am 
                                {/* (<span id="time_5">0</span>) */}
                                </label>
                            </li>
                            <li data-value="" className="checkbox">
                                <input
                                    id="at6 am to 12 pm"
                                    name="arrival_filter"
                                    value="arrival_6am_to_12pm"
                                    type="radio"
                                    onChange={updateFilterValue}
                                />
                                <label htmlFor="at6 am to 12 pm" className="filter_sub_sec_title"></label>
                                <span><i className="icon fl icon-afternoon"></i></span><label htmlFor="at6 am to 12 pm" className="cbox-label" title="6 am to 12 pm">6 am to 12 pm 
                                {/* (<span id="time_6">1</span>) */}
                                </label>
                            </li>
                            <li data-value="" className="checkbox">
                                <input
                                    id="at12 pm to 6 pm"
                                    name="arrival_filter"
                                    value="arrival_12pm_to_6pm"
                                    type="radio"
                                    onChange={updateFilterValue}
                                />
                                <label htmlFor="at12 pm to 6 pm" className="filter_sub_sec_title"></label>
                                <span><i className="icon fl icon-evening"></i></span>
                                <label htmlFor="at12 pm to 6 pm" className="cbox-label" title="12 pm to 6 pm">12 pm to 6 pm 
                                {/* (<span id="time_7">0</span>) */}
                                </label>
                            </li>
                            <li data-value="" className="checkbox">
                                <input
                                    id="atAfter 6 pm"
                                    name="arrival_filter"
                                    value="arrival_after_6pm"
                                    type="radio"
                                    onChange={updateFilterValue}
                                />
                                <label htmlFor="atAfter 6 pm" className="filter_sub_sec_title"></label>
                                <span><i className="icon fl icon-night"></i></span>
                                <label htmlFor="atAfter 6 pm" className="cbox-label" title="After 6 pm">After 6 pm 
                                {/* (<span id="time_8">0</span>) */}
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </FilterContainer>
        </>
    )
}
const FilterContainer = styled.div`
    border-radius: 5px;
    box-shadow: 0px 10px 10px #00000029;
    border: 1px solid #D6D6D6;
    padding: 20px;
    max-height: 620px;
    overflow-y: scroll;
    ::-webkit-scrollbar {width: 20px;display: none;}
    .f-bold{
        font-size: 15px;
        color: #2f3183;
        font-weight: 700;
    }
    .clear_filter_sec{
        color: #d93933;
        text-align: right;
        padding-bottom: 10px;
        span{
            background: #d93932;
            padding: 3px 7px;
            font-size: 14px;
            color: #fff;
            border-radius: 4px;
            cursor: pointer;
            font-weight: 500;
        }
    }
    .filter_title_sec{
        display: grid;
        grid-template-columns: 2.5fr 1fr;
        padding: 0px 0px 7px;
        border-bottom: 1px solid;
        margin-bottom: 15px;
        align-items: end;
        .filter_tile_label{
            font-size: 13px;
            color: #827f7fcc;
            margin-bottom: 0px;
        }
    }
    .filter_details_sec{
        .search_input{
            border: 1px solid #ccc;
            color: black;
            border-radius: 4px;
            width: 100%;
            margin-bottom: 10px;
            padding: 2px 10px;
        }
        .filter_sec_head_title{
            padding-bottom: 10px;
        }
        .filter_sub_sec{
            li{
                margin-bottom: 10px;
                .filter_sub_sec_title{
                    padding: 0px 0px 0px 20px;
                }
            }
        }
    }
`;
export default BusFilter
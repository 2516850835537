const BusReducer = (state, action) => {
    //console.log(action);
    switch (action.type) {
      case "SET_LOADING":
        return {
          ...state,
          isLoading: true,
          errorMsg : '',
        };
  
      case "SET_API_DATA":
        /* const featureData = action.payload.filter((curElem) => {
          return curElem.featured === true;
        }); */
        return {
          ...state,
          isError: false,
          isLoading: false,
          buses: action.payload,
          totalBuses: action.payload.length,
        };
  
      case "API_RESPONSE_ERROR":
        return {
          ...state,
          isLoading: false,
          isError: action.isError,
          errorMsg : action.errorMsg
        };
      case "API_ERROR":
        return {
          ...state,
          isLoading: false,
          isError: true,
          errorMsg : 'Something is wrong'
        };
  
      default:
        return state;
    }
  };
  
  export default BusReducer;
  
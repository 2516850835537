import React from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight} from '@fortawesome/free-solid-svg-icons';
import Helper from '../../util/Helper';

const TicketDetails = (props) => {
    const getGenderLabel = (gender) => {
        switch (gender) {
          case 0:
            return 'Male';
          case 1:
            return 'Female';
          case 2:
            return 'Other';
          default:
            return 'Unknown';
        }
    };
  return (
    <TickInDetails className="ticket" id="pdf-container">
        <div className="ticket-header">
            <div className="ticket-header-left">
                <img src="/images/yesbuslogo.png" alt="Buspartner" />
            </div>
            <div className='ticket_heading'>
                <h2 className='no-print'>E-ticket</h2>
            </div>
            <div className="ticket-header-right">
                <h3>Need help with your trip?</h3>
                <h3 className='bold'>Buspartner Customer Care : </h3>
                <p>Write to us <a href="mailto:contact@buspartner.in">contact@buspartner.in</a></p>
            </div>
        </div>
        <div className="ticket-info">
            <div className="ticket-info-left">
            <div className="from-to-div">
                <h4>{props.ticketData.source} <FontAwesomeIcon icon={faArrowRight} size="lg" color="#ee4139"/> {props.ticketData.destination}</h4>
            </div>
            <div className="doj-div">
                {Helper.formatDate(props.ticketData.doj)}
            </div>
            </div>
            <div className="ticket-info-right">
            <h5><span>Order No. : </span> {props.ticketData.payment_reference_no}</h5>
            <h5><span>Ticket No. : </span>{props.ticketData.booking_no}</h5>
            </div>
        </div>
        <div className="bus-details">
            <div>
            <h5>
                { 
                  props.ticketData.bus_info &&
                  props.ticketData.bus_info.bus_name
                }
            </h5>
            <span>
                {/* {
                  props.ticketData.bus_info &&
                  props.ticketData.bus_info.bustype.type_name
                } */}
            </span>
            </div>
            <div>
            <h5>{props.ticketData.boarding_point}</h5>
            <span>{props.ticketData.boarding_time}</span>
            </div>
            <div>
            <h5>{props.ticketData.dropping_point}</h5>
            <span>{props.ticketData.dropping_time}</span>
            </div>
            <div>
            <h5>Passanger(s)</h5>
            <span>
                {
                    props.ticketData.booking_info_detail &&
                    props.ticketData.booking_info_detail.length
                }
            </span>
            </div>
        </div>
        <div className="ticket-body">
            <div className="ticket-body-left">
            <h3>Passenger Details</h3>
            <div className="ticket-body-left-row">
                <table>
                    <thead>
                        <tr>
                        <th>SL.</th>
                        <th>Seat No.</th>
                        <th>Name</th>
                        <th>Age</th>
                        <th>Gender</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.ticketData.booking_info_detail &&
                            props.ticketData.booking_info_detail.map((passenger, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>{passenger.seat_number}</td>
                                        <td>{passenger.passanger_name}</td>
                                        <td>{passenger.passanger_age}</td>
                                        <td>{getGenderLabel(passenger.passanger_gender)}</td>
                                    </tr>
                                )
                            })
                        }
                        
                    </tbody>
                </table>
                <p>NOTE : This operator accepts mTicket, you need not carry a print out</p>
            </div>
            </div>
            <div className="ticket-body-right">
                <div className="bill-details_info">
                <div>
                    <label>Bus Fare : </label>
                    <span>{Helper.formatAsIndianRupee(props.ticketData.total_amount_paid+props.ticketData.payment_discount_amount)}</span>
                </div>
                <div>
                    <label>Promo Discount : </label>
                    <span>- {Helper.formatAsIndianRupee(props.ticketData.payment_discount_amount)}</span>
                </div>
                <div>
                    <label htmlFor="">Net Fare : </label>
                    <span>{Helper.formatAsIndianRupee(props.ticketData.total_amount_paid)}</span>
                </div>
                <div className='info'>(inclusive of GST and service charge, if any)</div>
                </div>
            </div>
        </div>
        <div className="tnc-body">
            <div className="tnc-body-head">
            <hr />
            <span>Terms and Conditions</span>
            <hr />
            </div>
            <div className="tnc-body-deatils">
            <div className='tnc-body-deatils-left'>
                <p>Buspartner* is an online ticketing platform. It does not operate bus services of its own. In order to provide a comprehensive choice of bus operators, departure times and prices to customers, it has tied up with many bus operators. Buspartner advice to customers is to choose bus operators they are aware of and whose service they are comfortable with.</p>
                <h6>Buspartner responsibilities include:</h6>
                <ul>
                <li>Issuing a valid ticket (a ticket that will be accepted by the bus operator) for its network of bus operators</li>
                <li>Providing refund and support in the event of cancellation</li>
                <li>Providing customer support and information in case of any delays / inconvenience</li>
                </ul>
                <h6>Buspartner responsibilities do not include:</h6>
                <ul>
                <li>The bus operators bus not departing / reaching on time.</li>
                <li>The bus operators employees being rude.</li>
                <li>The bus operators bus seats etc not being up to the customers expectation.</li>
                <li>The bus operator canceling the trip due to unavoidable reasons.</li>
                <li>The baggage of the customer getting lost / stolen / damaged.</li>
                <li>The bus operator changing a customers seat at the last minute to accommodate a lady / child</li>
                <li>The customer waiting at the wrong boarding point (please call the bus operator to find out the exact boarding point if you are not a regular traveler on that particular bus).</li>
                <li>The bus operator changing the boarding point and/or using a pick-up vehicle at the boarding point to take customers to the bus departure point.</li>
                </ul>
                <p>The departure time mentioned on the ticket are only tentative timings. However the bus will not leave the source before the time that is mentioned on the ticket.</p>
            </div>
            <div className='tnc-body-deatils-right'>
                <p>Passengers are required to furnish the following at the time of boarding the bus:</p>
                <ul>
                <li>A copy of the ticket (A print out of the ticket or the print out of the ticket e-mail).</li>
                <li>A valid identity proof Failing to do so, they may not be allowed to board the bus.</li>
                </ul>
                <p>Change of bus: In case the bus operator changes the type of bus due to some reason, Buspartner will refund the differential amount to the customer upon being intimated by the customers in 24 hours of the journey.</p>
                <p>Amenities for this bus as shown on Buspartner have been configured and provided by the bus provider (bus operator). These amenities will be provided unless there are some exceptions on certain days. Please note that Buspartner provides this information in good faith to help passengers to make an informed decision. The liability of the amenity not being made available lies with the operator and not with Buspartner.</p>
                <p>In case a booking confirmation e-mail and sms gets delayed or fails because of technical reasons or as a result of incorrect e-mail ID / phone number provided by the user etc, a ticket will be considered "booked" as long as the ticket shows up on the confirmation page of www.buspartner.in</p>
                <p>Grievances and claims related to the bus journey should be reported to Buspartner support team within 10 days of your travel date.</p>
                <p>Please note the following regarding the luggage policy for your journey :</p>
                <ul>
                <li>Each passenger is allowed to carry one bag of upto 10 kgs and one personal item such as a laptop bag, handbag, or briefcase of upto 5 kgs.</li>
                <li>Passengers should not carry any goods like weapons, inflammable, firearms, ammunition, drugs, liquor, smuggled goods etc and any other articles that are prohibited under law.</li>
                <li>Bus Operator reserves the right to deny boarding or charge additional amount in case passenger is travelling with extra luggage than what is mentioned above.</li>
                </ul>
                <p>Partial Cancellation is NOT allowed for this ticket. Charges for complete ticket cancellation are mentioned below.</p>
                <p>Buspartner cancellation charges for this ticket are as follows:</p>
                <div className="cancellation-tnc">
                    <div className='time'>
                        <h6>Cancellation time</h6>
                        {
                            props.ticketData.bus_info &&
                            props.ticketData.bus_info.cancellation_before_days.map((policy, index) => {
                                return (
                                    <span key={index}>Before {policy} Hour(s)</span>
                                )
                            })
                        }
                    </div>
                    <div className='refund'>
                        <h6>Refund Percentage</h6>
                        {
                            props.ticketData.bus_info &&
                            props.ticketData.bus_info.cancellation_charge.map((charge, index) => {
                                return (
                                    <span key={index}>{100-charge}%</span>
                                )
                            })
                        }
                    </div>
                </div>
                <p>Cancellation of this ticket is NOT allowed after bus departure time.</p>
            </div>
            </div>
            <div className="yesbus-info">
            <h5>Whom should i call?</h5>
            <div className="row-align">
                {/* {
                    props.ticketData.bus_info &&
                    props.ticketData.bus_info.bus_contact_info.map((contact, index) => {
                        return (
                            <div key={index}>
                                <h6>{contact.person_name}({contact.contact_designation})</h6>
                                <span>{contact.person_number}</span>
                            </div>
                        )
                    })
                } */}
                {/* <div>
                    <h6>(1)Conductor Mob</h6>
                    <span>+91-94-3908-7857</span>
                </div>
                <div>
                    <h6>(2)Conductor Mob</h6>
                    <span>+91-94-3908-7857</span>
                </div> */}
                <div>
                    <h6>Customer Care</h6>
                    
                    {
                      props.ticketData.bus_info &&
                      (
                        props.ticketData.bus_info.bus_customer_care_number!='' &&
                        props.ticketData.bus_info.bus_customer_care_number &&
                        props.ticketData.bus_info.bus_customer_care_number!='null'
                      ) ?
                      <span>{props.ticketData.bus_info.bus_customer_care_number}</span>
                      :
                      <span>+91-94-3908-7857</span>
                    }
                </div>
                <div>
                    <h6>For cancellation and refunds related</h6>
                    <span>Click on this link for hassle free online cancellation</span>
                </div>
                <div>
                    <h6>For all queries</h6>
                    <span>Call or Whats App 9835553444 or write to us</span>
                    <span><a href="mailto:contact@buspartner.in"> contact@buspartner.in</a></span>
                </div>
            </div>
            </div>
        </div>
    </TickInDetails>
  )
}

const TickInDetails = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    padding: 20px;
    margin-bottom: 50px;
    .ticket-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .ticket-header-left{
        p{
          font-size: 13px;
          color: gray;
        }
        img{
          width: 100px;
        } 
      }
      .ticket_heading{
        h2{
          text-decoration: underline;
          border-bottom: 1px solid #d13e38;
        }
      }
      .ticket-header-right{
        h3{
          font-size: 17px;
          font-weight: 600;
          margin-bottom: 5px;
        }
        h3.bold{
          font-weight: 700;
        }
        p{
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 0px;
          a{
            color: #0e63e1;
          }
        }
      }
    }
    .ticket-info{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      background-color: #e4e4e4;
      padding: 0px 10px;
      border-radius: 5px;
      .ticket-info-left{
        width: 75%;
        display: flex;
        gap: 25px;
        justify-content: flex-start;
        align-items: baseline;
        .from-to-div{
          padding: 6px 0px;
          width: 40%;
          h4{
            font-weight: 700;
            line-height: normal;
            margin-bottom: 0px;
            letter-spacing: 0.8px;
            color: #2e318d;
          }
        }
        .doj-div{
          padding: 6px 0px;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0px;
        }
      }
      .ticket-info-right{
        padding: 8px 0px;
        display: grid;
        gap: 5px;
        h5 {
          font-size: 15px;
          font-weight: 500;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          gap: 17px;
          color: rgb(46, 49, 141);
          span {
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.9px;
            color : #000;
          }
        }
      }
    }
    .bus-details{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 20px;
      div{
        text-align: right;
        h5{
          font-size: 19px;
          margin-bottom: 0px;
        }
        span{
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
    .ticket-body{
      display: flex;
      gap:25px;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: flex-end;
      ${'' /* border: 1px solid #ee4139; */}
      border: 2px solid transparent;
      border-image: linear-gradient(to right, #dc5043, #2f3188);
      border-image-slice: 1;
      padding: 15px 10px;
      .ticket-body-left{
        width: 60%;
        h3{
          font-size: 18px;
          font-weight: 600;
          ${'' /* margin: 0% 27%; */}
          text-align: center;
          letter-spacing: 0.9px;
          text-decoration: underline;
        }
        .ticket-body-left-row{
          align-items: center;
          margin-top: 10px;
          margin-bottom: 10px;
          table{
            font-family: Arial, Helvetica, sans-serif;
            border-collapse: collapse;
            width: 100%;
            td , th{
              border: 1px solid rgb(221, 221, 221);
              padding: 3px 8px;
            }
            tr:nth-child(even){background-color: #f2f2f2;}
            tr:hover {background-color: #ddd;}
            th {
              padding-top: 5px;
              padding-bottom: 5px;
              text-align: left;
              background-color: rgb(46 49 141);
              color: white;
            }
          }
          p{
            font-size: 13px;
            font-weight: 500;
            color: gray;
            padding-top: 6px;
            margin-bottom: 0px;
          }
        }
      }
      .ticket-body-right{
        .bill-details_info{
          display: grid;
          grid-template-rows: auto;
          gap: 5px;
          div{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            label{
              font-size: 16px;
              font-weight: 700;
            }
            span{
              font-size: 16px;
            }
          }
          .info{
            font-size: 13px;
            font-weight: 500;
            color: gray;
          }
        }
      }
    }
    .tnc-body{
      .tnc-body-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0px;
        hr{
          width: 38%;
          border-top: 3px solid #000;
          opacity: inherit;
          margin: 0px;
        }
        span{
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 0.8px;
        }
      }
      .tnc-body-deatils{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: 40px;
        font-size: 12px;
        .tnc-body-deatils-left{
          width: 50%;
          h6{
            text-align: left;
            margin: 15px 0px;
            font-size: 13px;
            font-weight: 700;

          }
          p{
            margin: 7px 0px;
          }
          ul{
            list-style: square;
            li{
              padding-bottom: 6px;
            }
          }
        }
        .tnc-body-deatils-right{
          width: 50%;
          h6{
            text-align: center;
            margin: 10px 0px;
            font-size: 13px;
            font-weight: 700;
          }
          p{
            margin: 7px 0px;
          }
          ul{
            list-style: square;
            li{
              padding-bottom: 2px;
            }
          }
          .cancellation-tnc{
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
            gap: 80px;
            .time{
              display: grid;
              grid-auto-rows: auto;
              gap: 5px;
              h6{
                text-align: left;
                margin: 2px 0px;
                text-decoration: underline;
              }
            }
            .refund{
              display: grid;
              grid-auto-rows: auto;
              gap: 5px;
              h6{
                text-align: left;
                margin: 2px 0px;
                text-decoration: underline;
              }
            }
          }
        }
      }
      .yesbus-info{
        h5{
          font-size: 16px;
          color: gray;
        }
        .row-align{
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          gap: 30px;
          font-size: 12px;
          div{
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
`;



export default TicketDetails
import React,{useEffect, useState} from 'react';
import styled from 'styled-components';
import { useFilterContext } from '../../context/FilterContext';
import Bus from './Bus';

const BusListingBody = (props) => {
  const [selectedBusId, setSelectedBusId] = useState(null);
  const { filter_buses, loading, errorIs, errormsg } = useFilterContext();
  useEffect(() => {
    setSelectedBusId(null);
  }, [filter_buses]);
  // console.log(filter_buses)
  return (
    <>
      <BusListingBodySec>
        <div className="bus_listing_head_section desk_mode">
          <span><a>Bus Name</a></span>
          <span><a>Departure</a></span>
          <span><a>Duration</a></span>
          <span><a>Arrival</a></span>
          <span><a>Fare</a></span>
          <span><a>Seats</a></span>
        </div>
        {
          props.returnbus=='True' ? 
          (
            <>
              <div className="bus_listing_body_sec">
                {
                  loading===true ? 
                    <div className='bus_list_befor_content'>Loading......</div> 
                  : 
                    errorIs == true ? 
                      <div className='bus_list_befor_content'>
                      {/* {errormsg} */}
                      No Bus Found
                      </div> 
                    :
                      filter_buses.length>0 ?
                        (
                          <>
                            <div className='bus_with_dtls_sec' key={filter_buses[0].id} style={{display: selectedBusId === filter_buses[0].id || selectedBusId==null ? '' : 'none'}}>
                              <Bus bus={filter_buses[0]} returnbus={props.True} source={props.source} destination={props.destination} doj={props.doj} setSelectedBusId={setSelectedBusId} setBlogShowStatus={props.setBlogShowStatus}/>
                            </div>
                            {
                              filter_buses.length>1 &&
                                <div className='bus_with_dtls_sec' key={filter_buses[1].id} style={{display: selectedBusId === filter_buses[1].id || selectedBusId==null ? '' : 'none'}}>
                                  <Bus bus={filter_buses[1]} returnbus={props.True} source={props.destination} destination={props.source} doj={props.doj} setSelectedBusId={setSelectedBusId} setBlogShowStatus={props.setBlogShowStatus}/>
                                </div>
                            }
                            
                          </>
                        )
                      :
                      'No Bus Found'
                }
              </div>
            </>
          ) : 
          (
            <>
            <div className="bus_listing_body_sec">
              {
                loading===true ? 
                  <div className='bus_list_befor_content'>Loading......</div> 
                : 
                  errorIs == true ? 
                    <div className='bus_list_befor_content'>
                    {/* {errormsg} */}
                    No Bus Found
                    </div> 
                  :
                    filter_buses.length>0 ?
                      filter_buses.map((bus, index) => {
                        return <div className='bus_with_dtls_sec' key={bus.id} style={{display: selectedBusId === bus.id || selectedBusId==null ? '' : 'none'}}>
                          <Bus bus={bus} returnbus={props.True} source={props.source} destination={props.destination} doj={props.doj} setSelectedBusId={setSelectedBusId} setBlogShowStatus={props.setBlogShowStatus}/>
                        </div>
                      })
                    :
                    'No Bus Found'
              }
            </div>
            </>
          )
        }
        
      </BusListingBodySec>
    </>
  )
}
const BusListingBodySec = styled.div`
    .bus_listing_head_section {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 10px;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 10px;
      border: 1px solid rgb(214, 214, 214);
      background: #fdfdfdcc;
      padding: 15px;
      span{
        color: rgb(47, 49, 131);
        font-size: 17px;
        font-weight: 700;
        text-transform: capitalize;
      }
    }
    .bus_listing_body_sec{
      .bus_list_befor_content{
        text-align: center;
        font-size: 20px;
        padding-top: 24px;
        font-weight: 600;
        color: #2f3183;
      }
      .bus_with_dtls_sec{
        margin-top: 25px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 10px;
        border: 1px solid rgb(214, 214, 214);
        
        .bus_dtls_div{
          min-height: 32px;
          ${'' /* background: rgb(215 215 215 / 49%); */}
          background: rgb(47 49 131);
          color: rgb(217 57 50);
          padding: 5px 10px;
          ${'' /* border-radius: 0 0 6px 6px; */}
          z-index: 1;
          ul{
            padding: 0px;
            display: grid;
            grid-template-columns: 2.5fr 1fr 1fr;
            grid-gap: 10px;
            li{
              ${'' /* color: #0c10bc; */}
              color: rgb(217 57 50);
              font-weight: 600;
              a{
                color: rgb(217 57 50);
              }
            }
            .cancellation_policy_cls{
              text-align: right;
            }
          }
        }
        .bus_dtls_div_sec{
          ${'' /* min-height: 32px; */}
          background: rgb(228, 229, 244);
          color: rgb(47, 49, 131);
          padding: 0px 10px;
          z-index: 1;
          .bus_borddrop_cancellation_sec{
            display: flex;
            justify-content: space-between;
            padding-top: 10px;
            .bd_side_head{
              color: #727272;
            }
            .left_side_details{
              ul{
                display: block;
                list-style: circle;
                padding-left: 20px;
                padding-top: 10px;
                li{
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
      .bus_indi_sec{
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
        ${'' /* background: rgba(253, 253, 253, 0.8); */}
        padding: 10px;
        word-break: break-all;
        .bus_name_type_cls{
          .bus_name_cls{
            font-size: 17px;
            text-transform: capitalize;
            line-height: 22px;
            font-weight: 700;
            margin: 5px 0px;
            color: #2f3183;
          }
          .bus_type {
            position: relative;
            font-size: 12px;
            color: #9d9d9d;
            text-transform: uppercase;
          }
        }
        .bus_departure_cls{
          .bus_duration_time_cls {
            text-align: left;
            text-transform: uppercase;
            color: #605656;
            font-weight: 700;
          }
          span {
            font-size: 15px;
            margin-bottom: 10px;
            position: relative;
            top: 6px;
            color: #8a8686;
          }
        }
        .bus_duration_cls{
          .icon_div_cls {
            text-align: left;
            padding-left: 28px;
            color: #2f3183;
            font-size: 18px;
            padding-bottom: 5px;
          }
          .bus_fare_span_cls{
            text-align: left;
            padding-left: 5px;
            color: #2f3183;
            font-size: 18px;
            padding-bottom: 5px;
          }
          .api_fare_starts_from{
            text-transform: capitalize;
            display: block;
            margin-bottom: auto;
            font-size: 13px;
          }
          .bus_seat_span_icn{
            text-align: left;
            padding-left: 15px;
            color: #2f3183;
            font-size: 18px;
            padding-bottom: 5px;
          }
          span {
            ${'' /* font-size: 15px; */}
            margin-bottom: 10px;
            position: relative;
            text-transform: uppercase;
            ${'' /* color: #8a8686; */}
          }
          span.bus_seat_span_cls{
            text-transform: capitalize !important;
            padding: 3px 4px;
            color: #fff;
            background: #26d426;
            font-size: 13px !important;
            border-radius: 3px;
          }
        }
        .view_dtls_sec{
          text-align: center;
          margin-top: 10px;
          .view_seat{
            padding: 6px 10px;
            border-radius: 4px;
            font-size: inherit;
            ${'' /* background-color: #2d318d; */}
            text-decoration: none;
            background-color: #fff;
            color: rgb(45, 49, 141);
            border: 1px solid rgb(45, 49, 141);
            cursor: pointer;
            font-weight: 600;
          }
          .booking_closed{
            padding: 6px 4px;
            border-radius: 4px;
            font-size: 15px;
            text-decoration: none;
            background-color: #fff;
            color: rgb(217, 57, 50);
            border: 1px solid rgb(217, 57, 50);
            cursor: auto;
            font-weight: 600;
            
          }
        }
      }
      
    }
    @media (min-width:250px) and (max-width:400px) {
      .bus_listing_body_sec .bus_with_dtls_sec{
        margin-top: 0px;
        margin-bottom: 18px;
        border-radius: 18px;
      }
      .bus_indi_sec{
        .bus_dtls_div_sec{
          background : rgb(228 229 244)!important;
          border-radius: 6px !important;
          color: #000 !important;
          font-size: 12px !important;
          margin-top: 10px;
          .bus_borddrop_cancellation_sec{
            padding-bottom: 10px;
          }
          .bus_borddrop_cancellation_sec .left_side_details ul{
            list-style: outside !important;
          }
          .bus_borddrop_cancellation_sec .bd_side_head{
            font-size: 13px;
            color: #2e3080 !important;
            font-weight: 600;
            text-decoration: underline;
            text-decoration-color: rgb(217, 57, 50);
            text-decoration-thickness: 1.2px;
            text-underline-offset: 5px;
          }
        }
        .bus_indi_sec_last_div_details{
          display: flex;
          border-top: 1px solid #d6cfcf;
          margin-top: 5px;
          justify-content: space-between;
          align-items: baseline;
          font-size: 14px;
          padding-top: 10px;
          .bus_detls_hd{
              font-size: 13px;
              font-weight: 600;
             
              text-underline-offset: 5px;
              a{
                color:rgb(45, 49, 141);
                font-size: 11px;
              }
          }
        }
        .bus_indi_sec_last_div{
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          border-top: 1px solid #d6cfcf;
          .via_sec{
            .bus_dtls_div{
              background: none;
              .via_cls{
                span{
                  font-size: 12px;
                }
              }
            }
          }
          .view_dtls_sec{
            .view_seat{
              font-size: 12px !important;
              font-weight: 500 !important;
              padding: 5px !important;
              background-color: rgb(45, 49, 141);
              color: #fff;
              text-transform: uppercase;
            }
          }
        }
        .bus_indi_sec_first_div{
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          margin: 0px 0px 15px;
          .bus_name_type_cls .bus_name_cls{
            margin: 0px;
            font-size: 14px;
          }
          .bus_name_type_cls .bus_type{
            font-size: 10px;
          }
          .bus_duration_cls span.bus_seat_span_cls{
            padding: 0px 5px 1px 5px;
          }
          .bus_src_details{
            flex: 0 0 85%;
            display: flex;
            justify-content: left;
            gap: 5px;
            .bus_duration_time_cls{
              font-size: 12px;
            }
            .bus_duration_cls{
              flex: 0 1 40%;
              display: flex;
              justify-content: center;
              align-items: end;
              span{
                font-size: 10px;
                margin: 0px;
                color: #8a8686;
              }
              svg{
                color: rgb(238, 65, 57);
              }
            }
          }
          .bus_fare_mob{
              color: rgb(46, 49, 136);
              font-weight: 700;
              font-size: 15px;
          }
        }
        .bus_name_type_cls{
          flex: 0 1 100%;
        }
        .bus_departure_cls{
          flex: 0 1 25%;
        }
        .bus_duration_cls{
          flex: 0 1 30%;
        }
        .view_dtls_sec{
          text-align: right !important;
          margin-top: 10px !important;
          flex: 0 0 32% !important;
        }
      }
      .bus_listing_body_sec .bus_with_dtls_sec .bus_dtls_div ul{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: space-around;
        row-gap: 4px;
      }
      .bus_listing_body_sec .bus_with_dtls_sec .bus_dtls_div ul .cancellation_policy_cls{
        text-align: left;
      }
      .bus_listing_body_sec .bus_with_dtls_sec .bus_dtls_div ul li{
        font-size: 11px;
      }
      .bus_listing_body_sec .bus_indi_sec .view_dtls_sec .view_seat{
        font-size: 14px;
        font-weight: 600;
        padding: 5px 5px;
      }
      .bus_listing_body_sec .bus_indi_sec{
        display: block;
        width: 100%;
      }
    }
    @media (min-width:400px) and (max-width:767px) {
      .bus_listing_body_sec .bus_with_dtls_sec{
        margin-top: 0px;
        margin-bottom: 18px;
        border-radius: 18px;
      }
      .bus_indi_sec{
        .bus_dtls_div_sec{
          background : rgb(228 229 244)!important;
          border-radius: 6px !important;
          color: #000 !important;
          font-size: 12px !important;
          margin-top: 10px;
          .bus_borddrop_cancellation_sec{
            padding-bottom: 10px;
          }
          .bus_borddrop_cancellation_sec .left_side_details ul{
            list-style: outside !important;
          }
          .bus_borddrop_cancellation_sec .bd_side_head{
            font-size: 13px;
            color: #2e3080 !important;
            font-weight: 600;
            text-decoration: underline;
            text-decoration-color: rgb(217, 57, 50);
            text-decoration-thickness: 1.2px;
            text-underline-offset: 5px;
          }
        }
        .bus_indi_sec_last_div_details{
          display: flex;
          border-top: 1px solid #d6cfcf;
          margin-top: 5px;
          justify-content: space-between;
          align-items: baseline;
          font-size: 14px;
          padding-top: 10px;
          .bus_detls_hd{
              font-size: 13px;
              font-weight: 600;
             
              text-underline-offset: 5px;
              a{
                color:rgb(45, 49, 141);
              }
          }
        }
        .bus_indi_sec_last_div{
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          border-top: 1px solid #d6cfcf;
          .via_sec{
            .bus_dtls_div{
              background: none;
              .via_cls{
                span{
                  font-size: 12px;
                }
              }
            }
          }
          .view_dtls_sec{
            .view_seat{
              font-size: 12px !important;
              font-weight: 500 !important;
              padding: 5px !important;
              background-color: rgb(45, 49, 141);
              color: #fff;
              text-transform: uppercase;
            }
          }
        }
        .bus_indi_sec_first_div{
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          margin: 0px 0px 15px;
          .bus_name_type_cls .bus_name_cls{
            margin: 0px;
          }
          .bus_duration_cls span.bus_seat_span_cls{
            padding: 0px 5px 1px 5px;
          }
          .bus_src_details{
            flex: 0 0 85%;
            display: flex;
            justify-content: left;
            gap: 5px;
            .bus_duration_time_cls{
              font-size: 16px;
            }
            .bus_duration_cls{
              flex: 0 1 40%;
              display: flex;
              justify-content: left;
              align-items: end;
              span{
                font-size: 13px;
                margin: 0px;
                color: #8a8686;
              }
              svg{
                color: rgb(238, 65, 57);
              }
            }
          }
          .bus_fare_mob{
              color: rgb(46, 49, 136);
              font-weight: 700;
              font-size: 19px;
          }
        }
        .bus_name_type_cls{
          flex: 0 1 100%;
        }
        .bus_departure_cls{
          flex: 0 1 25%;
        }
        .bus_duration_cls{
          flex: 0 1 22%;
        }
        .view_dtls_sec{
          text-align: right !important;
          margin-top: 10px !important;
          flex: 0 0 32% !important;
        }
      }
      .bus_listing_body_sec .bus_with_dtls_sec .bus_dtls_div ul{
        display: flex;
        ${'' /* flex-flow: row wrap; */}
        justify-content: flex-start;
        align-content: space-around;
        row-gap: 12px;
        column-gap: 10px;
      }
      .bus_listing_body_sec .bus_with_dtls_sec .bus_dtls_div ul .cancellation_policy_cls{
        text-align: left;
      }
      .bus_listing_body_sec .bus_with_dtls_sec .bus_dtls_div ul li{
        font-size: 13px;
      }
      .bus_listing_body_sec .bus_indi_sec .view_dtls_sec .view_seat{
        font-size: 14px;
        font-weight: 600;
        padding: 5px 5px;
      }
      .bus_listing_body_sec .bus_indi_sec{
        display: block;
        width: 100%;
      }
    }
    @media (min-width: 768px) and (max-width: 991px) {
      .bus_listing_body_sec .bus_indi_sec{
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: space-between;
        align-items: end;
      }
      .bus_listing_body_sec .bus_indi_sec .view_dtls_sec .view_seat{
        font-size: 13px;
        font-weight: 700;
        padding: 6px;
      }
    }
    @media (min-width: 992px) and (max-width: 1095px) {
      .bus_listing_body_sec{
        .bus_indi_sec{
          grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 2fr;
        }
        
      }
    }
    @media (min-width: 1096px) {
      .bus_listing_body_sec{
        .bus_indi_sec{
          grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1.5fr;
        }
      }
    }
`;
export default BusListingBody
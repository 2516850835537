import React,{useState,useEffect} from 'react';
import { useLocation, useNavigate,  Link } from 'react-router-dom';
import { faTimes, faAngleRight, faAngleLeft, faArrowRightLong, faExchangeAlt, faArrowLeft, faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import styled from 'styled-components';
import Autocomplete from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import Modal from 'react-modal';



import Helper from '../util/Helper';
import BusListingBody from './journey/BusListingBody';
import BusFilter from './journey/BusFilter';
import Blogs from './journey/Blogs';
import OtherBlogs from './journey/OtherBlogs';

// import { useFilterContext } from '../context/FilterContext';

const BusList = () => {
    Modal.setAppElement('#root');
    const isMobile = useMediaQuery('(max-width: 767px)');
    const [showModal, setShowModal] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [blogShowStatus, setBlogShowStatus] = useState(true);

    const handleScroll = () => {
        const scrollY = window.scrollY;
        const stickyThreshold = 100;
        if (scrollY > stickyThreshold) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
    };
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiToken = process.env.REACT_APP_API_TOKEN;

    const [modifySearch, setModifySearch] = useState(false);
    const [cities, setCities] = useState([]);

    const location = useLocation();
    const slug = location.pathname.replace('/bus/', '');
    const [sourceCitySlug, destCityWithDateSlug] = slug.split('-to-');
    const [destCitySlug, slugdateSlug] = destCityWithDateSlug.split('/');
    
    const today = new Date();
    const dateSlug = slugdateSlug || `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

    const history = useNavigate();

    const [filteredSourceCities, setFilteredSourceCities] = useState([]);
    
    
    const [selectedSourceCity, setSelectedSourceCity] = useState({});
    const [selectedDestCity, setSelectedDestCity] = useState({});


    const handleSourceCityChange = (event, newValue) => {
        setSelectedSourceCity(newValue);
    };
    const handleSourceCitySearch = (event, value) => {
        const searchTerm = value.trim().toLowerCase();
        const filteredOptions = cities.filter((city) =>
        city.city_name.toLowerCase().includes(searchTerm)
        );
        setFilteredSourceCities(filteredOptions);
    };
    const getOptionLabel = (option) => {
        if (!option || !option.city_name) {
            return "Select City";
        }
        return option.city_name;
    };
    
    // For destinarion
    const [filteredDestCities, setFilteredDestCities] = useState([]);
   
    const handleDestCityChange = (event, newValue) => {
        setSelectedDestCity(newValue);
    };
    const handleDestCitySearch = (event, value) => {
        const searchTerm = value.trim().toLowerCase();
        const filteredOptions = cities.filter((city) =>
          city.city_name.toLowerCase().includes(searchTerm)
        );
        setFilteredDestCities(filteredOptions);
    };
    // end

    const [selectedDate, setSelectedDate] = useState(new Date());
    useEffect(() => {
        // Fetch cities list from API endpoint using Axios
        axios.get(apiUrl+'/get-cities-list', { 
            headers: { 
                'YESBUS_API_KEY': apiKey,
                Authorization: `Bearer ${apiToken}`,
            }
        })
        .then((response) => {
            setCities(response.data.data);
            setFilteredSourceCities(response.data.data);
            const defaultSourceValue = response.data.data.find(city => city.city_name === sourceCitySlug) || {};
            setSelectedSourceCity(defaultSourceValue);
            const defaultDestValue = response.data.data.find(city => city.city_name === destCitySlug) || {};
            setSelectedDestCity(defaultDestValue);
            setFilteredDestCities(response.data.data);
        })
        .catch((error) => {
            console.error('Error fetching cities:', error);
        });
    }, []);

    useEffect(()=>{
        if(selectedSourceCity){
            document.getElementById('destination_fld').focus();
            //destCityRef.current.querySelector('input').focus();
        }
    },[selectedSourceCity]);
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}-${month}-${year}`;
    };
    const searchBus = () => {
        document.body.classList.remove('body-no-scroll');
        const newSourceSlug = selectedSourceCity.city_name;
        const newDestSlug = selectedDestCity.city_name;
        const newDateSlug = formatDate(selectedDate);
        const newSlug = `/bus/${newSourceSlug}-to-${newDestSlug}/${newDateSlug}`;
        history(newSlug);
        setModifySearch(false);
    }
    
    const previousDoj = () => {
        let [day, month, year] = dateSlug.split('-').map(Number);
        let currentDate = new Date(year, month - 1, day);
        let previousDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);

        let today = new Date();
        today.setHours(0, 0, 0, 0);
        if (previousDate < today) {
            return false; // Return false if previous date is older
        }

        let previousDay = previousDate.getDate();
        let previousMonth = previousDate.getMonth() + 1; // Month is 0-indexed, so add 1
        let previousYear = previousDate.getFullYear();
        let formattedPreviousDate = `${String(previousDay).padStart(2, '0')}-${String(previousMonth).padStart(2, '0')}-${previousYear}`;
        const slugUrlPrevious = `/bus/${sourceCitySlug}-to-${destCitySlug}/${formattedPreviousDate}`;
        
        history(slugUrlPrevious);
    }
    const nextDoj = () => {
        let [day, month, year] = dateSlug.split('-').map(Number);
        let currentDate = new Date(year, month - 1, day);
        let nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
        let nextDay = nextDate.getDate();
        let nextMonth = nextDate.getMonth() + 1; // Month is 0-indexed, so add 1
        let nextYear = nextDate.getFullYear();
        let formattedNextDate = `${String(nextDay).padStart(2, '0')}-${String(nextMonth).padStart(2, '0')}-${nextYear}`;

        const slugUrlNext = `/bus/${sourceCitySlug}-to-${destCitySlug}/${formattedNextDate}`;
        history(slugUrlNext);
    }

    const interchangeRoutes = () => {
        setSelectedSourceCity(selectedDestCity);
        setSelectedDestCity(selectedSourceCity);
    }
    const publiUrl = process.env.REACT_APP_URL+'bus/'+sourceCitySlug+'-to-'+destCitySlug;
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        // Set the showModal state variable as needed
        if (modifySearch && isMobile) {
            document.body.classList.add('body-no-scroll');
            setShowModal(true);
        }
    }, [modifySearch, isMobile]);

    const keywordsContent = `Online bus ticket booking, private bus in odisha, ${sourceCitySlug} To ${destCitySlug} Bus Ticket, ${sourceCitySlug} To ${destCitySlug} Bus Fare, ${sourceCitySlug} To ${destCitySlug} Bus Time Table`;
    const descriptionContent = `Book Bus Tickets from ${sourceCitySlug} To ${destCitySlug} from Buspartner, Odisha's  Best Online Bus Ticket Booking Platform, offers bus travels, ac volvo bus at best rates.`;

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{sourceCitySlug} To {destCitySlug} Bus ticket Booking, seat fare | Buspartner</title>
                    <meta name="description" content={descriptionContent} />
                    <meta name="Keywords" content={keywordsContent} />
                    <link rel="canonical" href={publiUrl} />
                </Helmet>
            </HelmetProvider>
            <SearchBarHead className={isSticky ? 'sticky-head-bar' : ''}>
                <div className='desk_mode'>
                    <div className="modify-sec-onward modify-sec cf">
                        <div className='fstdiv'>
                            <img src={process.env.PUBLIC_URL + '/images/yb1.png'} alt="Buspartner" title="Buspartner" />
                        </div>
                        <div className="jour-info cf">
                            <div className="search_detls_div">
                                <span className="src" dangerouslySetInnerHTML={{ __html: decodeURIComponent(sourceCitySlug) }} />
                                <FontAwesomeIcon icon={faArrowRightLong}/>
                                <span className="dst" dangerouslySetInnerHTML={{ __html: decodeURIComponent(destCitySlug) }} />
                                <span className="prev">
                                    <FontAwesomeIcon icon={faAngleLeft} onClick={previousDoj}/>
                                </span>
                                <span id="searchDat" className="searchDate">{Helper.formatDate(dateSlug)}</span>
                                <span className="next">
                                    <FontAwesomeIcon icon={faAngleRight} onClick={nextDoj}/>
                                </span>
                                <div className="modify-button" onClick={()=>{setModifySearch(true)}}>Modify</div>
                            </div>
                        </div>
                    </div>
                    <section className="cf search-wrapper" style={modifySearch===true ? {display:''} : {display:'none'}}>
                            <div className="search-section">
                                <div className="parent-textbox fl">
                                    <div className="top-lbl">FROM </div>
                                    <div className="items">
                                    {/* <input type="text" autoComplete="off" id="originfrom" value={sourceCitySlug} /> */}
                                    <Autocomplete
                                        options={filteredSourceCities}
                                        value={selectedSourceCity}
                                        onChange={handleSourceCityChange}
                                        onInputChange={handleSourceCitySearch}
                                        getOptionLabel={getOptionLabel}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                            <input {...params.inputProps} placeholder="Enter Source" id="source_fld" className="" />
                                            </div>
                                        )}
                                        renderOption={(props, option) => (
                                            <li key={option.id} {...props}>{option.city_name}</li>
                                        )}
                                    />
                                    <div className="keywrd_pop_topS" id="topSrc1" style={{display: 'none'}}></div>
                                    </div>
                                </div>
                                <span className="icon-doublearrow icon fl" id="switchButton">
                                    <FontAwesomeIcon icon={faExchangeAlt} size="lg" color="#ee4139" onClick={interchangeRoutes}/>
                                </span>
                                <div className="parent-textbox fl">
                                    <div className="top-lbl">To</div>
                                    <div className="items">
                                    {/* <input type="text" autoComplete="off" id="originto" value={destCitySlug} /> */}
                                    <Autocomplete
                                        options={filteredDestCities}
                                        value={selectedDestCity}
                                        onChange={handleDestCityChange}
                                        onInputChange={handleDestCitySearch}
                                        getOptionLabel={getOptionLabel}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                            <input {...params.inputProps} id="destination_fld" placeholder="Enter Destination" className="" />
                                            </div>
                                        )}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>{option.city_name}</li>
                                        )}
                                    />
                                    <div className="keywrd_pop_topS" id="topSrc2" style={{display: 'none'}}></div>
                                    </div>
                                </div>
                                <div className="textbox fl">
                                    <div className="top-lbl">DATE</div>
                                    {/* <input id="onward_cal" name="doj" className="datepickers hasDatepicker" type="text" value={dateSlug} /> */}
                                    <DatePicker id="entryDate" className="form-control calendar" placeholder="Journey Date" selected={selectedDate} onChange={(date) => setSelectedDate(date)} dateFormat="dd/MM/yyyy" />
                                    <div id="onward_modify" className="calendar-blk-search calendar-blk-ond hide"></div>
                                </div>
                                <div className="parent-textbox fl">
                                    <button name="b" type="button" className="button ms-btn" onClick={searchBus}>SEARCH</button>
                                </div>
                                <div className="fr" id="mod_cancel"><i className="icon icon-cross"></i></div>
                            </div>
                        <FontAwesomeIcon icon={faTimes} color="rgb(46, 49, 136)" onClick={()=>{setModifySearch(false)}}/>
                    </section>
                </div>
                <div className='mobile-list-top mob_mode'>
                    <div className='row mobile-list-top-row'>
                        <div className="col-1">
                            <FontAwesomeIcon icon={faArrowLeft} className='color-red'/>
                        </div>
                        <div className="col-8 mob-list-center text-center">
                            <div className='search_details_mob'>
                                <span dangerouslySetInnerHTML={{ __html: decodeURIComponent(sourceCitySlug) }} />
                                <FontAwesomeIcon icon={faArrowRightLong}/>
                                <span dangerouslySetInnerHTML={{ __html: decodeURIComponent(destCitySlug) }} />
                            </div>
                            <span className="date_details_mob"> 
                                <span className="color-red">
                                    <FontAwesomeIcon icon={faAngleDoubleLeft} onClick={previousDoj}  />
                                </span>
                                <span className='date-travel'>{Helper.formatDate(dateSlug)}</span>
                                <span className="color-red">
                                    <FontAwesomeIcon icon={faAngleDoubleRight} onClick={nextDoj} />
                                </span>
                            </span>
                        </div>
                        <div className="col-3">
                            <Link className="btn modify-button" onClick={()=>{setModifySearch(true);}}>Modify</Link>
                        </div>
                    </div>
                    {
                        showModal &&
                        <Modal 
                        isOpen={modifySearch===true ? true : false}
                        onRequestClose={()=>{setModifySearch(false);document.body.classList.remove('body-no-scroll');}}
                        contentLabel="Example Modal"
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                        >
                            <div className="modal_main_sec_inner">
                                <div className='modal_head_close_sec'>
                                    <button type="button" className="close" data-dismiss="modal" onClick={()=>{setModifySearch(false);document.body.classList.remove('body-no-scroll')}}>
                                        <img src={process.env.PUBLIC_URL + '/images/Close_red_btn.svg'} alt="Buspartner" />
                                    </button>
                                    <h6 id="productname_check_stock">Modify Your Search</h6>
                                </div>
                                <div className="modal_main_sec_inner_body">
                                <div className="parent-textbox fl">
                                        <div className="items">
                                        <Autocomplete
                                            options={filteredSourceCities}
                                            value={selectedSourceCity}
                                            onChange={handleSourceCityChange}
                                            onInputChange={handleSourceCitySearch}
                                            getOptionLabel={getOptionLabel}
                                            renderInput={(params) => (
                                                <div ref={params.InputProps.ref}>
                                                <input {...params.inputProps} placeholder="Enter Source" id="source_fld" className="form-control" />
                                                </div>
                                            )}
                                            renderOption={(props, option) => (
                                                <li key={option.id} {...props}>{option.city_name}</li>
                                            )}
                                        />
                                        <div className="keywrd_pop_topS" id="topSrc1" style={{display: 'none'}}></div>
                                        </div>
                                    </div>
                                    <div className="icon-doublearrow icon fl" id="switchButton">
                                        <FontAwesomeIcon icon={faExchangeAlt} size="lg" color="#ee4139" onClick={interchangeRoutes}/>
                                    </div>
                                    <div className="parent-textbox fl">
                                        <div className="items">
                                        <Autocomplete
                                            options={filteredDestCities}
                                            value={selectedDestCity}
                                            onChange={handleDestCityChange}
                                            onInputChange={handleDestCitySearch}
                                            getOptionLabel={getOptionLabel}
                                            renderInput={(params) => (
                                                <div ref={params.InputProps.ref}>
                                                <input {...params.inputProps} id="destination_fld" placeholder="Enter Destination" className="form-control" />
                                                </div>
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>{option.city_name}</li>
                                            )}
                                        />
                                        <div className="keywrd_pop_topS" id="topSrc2" style={{display: 'none'}}></div>
                                        </div>
                                    </div>
                                    <div className="textbox fl">
                                        <DatePicker id="entryDate" className="form-control calendar" placeholder="Journey Date" selected={selectedDate} onChange={(date) => setSelectedDate(date)} dateFormat="dd/MM/yyyy" />
                                        <div id="onward_modify" className="calendar-blk-search calendar-blk-ond hide"></div>
                                    </div>
                                    <div className="parent-textbox fl">
                                        <button name="b" type="button" className="button ms-btn search" onClick={searchBus}>SEARCH</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    }
                    
                </div>
            </SearchBarHead>
            <section className='bus_listing_body_sec'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-3 desk_mode'>
                            <BusFilter/>
                        </div>
                        <div className='col-lg-9 pd-zero-mob'>
                            <BusListingBody returnbus="False" source={sourceCitySlug} destination={destCitySlug} doj={dateSlug} setBlogShowStatus={setBlogShowStatus}/>
                        </div>
                        {
                            blogShowStatus === true &&
                            <div className='col-lg-12'>
                                <Blogs/>
                            </div>
                        }
                        <div className='col-lg-12'>
                            <OtherBlogs/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const customStyles = {
    overlay: {
        backgroundColor: 'rgb(2 2 5 / 59%)', // Adjust the color and opacity as needed
        zIndex: '9999',
    },
    content: {
      top: '50%',          // Center the modal vertically
      left: '50%',         // Center the modal horizontally
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%', // Adjust based on modal width
      transform: 'translate(-50%, -50%)', // Center the modal both horizontally and vertically
      width: '85%',        // Adjust the modal width as needed
      backgroundColor: 'white',
      border: '1px solid #ccc',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      padding: '0px',
      overflow: 'visible',
    },
};
const SearchBarHead = styled.div`
    padding-bottom: 20px;
    
    ${'' /* mob hed */}
        .mobile-list-top{
            @media (min-width:390px) and (max-width:768px) {
                font-size: 17px;
            }
            @media (min-width:250px) and (max-width:390px) {
                font-size: 14px;
                .date-travel{
                    font-size: 12px;
                }
            }
            .color-red{
                color: #ee4139;
                font-size: 25px;
            }
            .mobile-list-top-row{
                background: rgb(46, 49, 136);
                color: rgb(255, 255, 255);
                padding: 10px 5px;
                font-weight: 600;
                align-items: center;
                @media (min-width:390px) and (max-width:768px) {
                    .fa-arrow-left{
                        font-size: 25px;
                    }
                }
                .mob-list-center{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    .search_details_mob{
                        display: flex;
                        ${'' /* justify-content: space-evenly; */}
                        justify-content: center;
                        align-items: flex-end;
                        gap: 10px;
                    }
                    .date_details_mob{
                        display: flex;
                        justify-content: center;
                        gap: 15px;
                        font-size: 14px;
                        align-items: center;
                    }
                }
                .modify-button{
                    border: 1px solid #fff;
                    border-radius: 3px;
                    display: inline-block;
                    font-weight: 600;
                    cursor: pointer;
                    color: #fff;
                    @media (min-width:390px) and (max-width:768px) {
                        padding: 5px 20px;
                        font-size: 14px;
                    }
                    @media (min-width:250px) and (max-width:390px) {
                        padding: 4px 8px;
                        font-size: 12px;
                    }
                }
            }

            @media (min-width:250px) and (max-width:390px) {
                .fa-arrow-left {
                    font-size: 20px;
                }
            }
        }
    ${'' /* end */}
    
    .modify-sec-onward{
        .fstdiv{
            display:none;
        }
        display: flex;
        ${'' /* justify-content: space-between; */}
        background: #2e3188;
        border-bottom: 1px solid #ddd;
        padding: 15px 95px;
        color: #fff;
        font-weight: 700;
        justify-content: left;
        gap: 50px;
        .jour-info{
            display: flex;
            ${'' /* grid-template-columns: 1.5fr 1fr; */}
            .search_detls_div{
                display: flex;
                grid-column-gap: 30px;
                align-items: center;
                .prev{
                    cursor: pointer;
                }
                .next{
                    cursor: pointer;
                }
                .modify-button{
                    border: 1px solid #fff;
                    border-radius: 3px;
                    padding: 5px 20px;
                    display: inline-block;
                    font-weight: 400;
                    cursor: pointer;
                }
            }
        }
    }
    .search-wrapper{
        background: rgb(230 230 230);
        padding: 15px 95px;
        display: flex;
        justify-content: space-between;
        .search-section{
            display: flex;
            justify-content: flex-start;
            column-gap: 50px;
            .parent-textbox{
                position: relative;
                display: grid;
                align-items: end;
                .top-lbl{
                    ${'' /* position: absolute;
                    top: -10px;
                    left: 0px; */}
                    font-size: 12px;
                    color: #999;
                    font-weight: 700;
                }
                .items{
                    position: relative;
                    input{
                        width: 100%;
                        height: 36px;
                        border: 1px solid #ddd;
                        border-radius: 3px;
                        padding: 0px 10px;
                        font-size: 14px;
                        color: #333;
                        font-weight: 400;
                        &:focus{
                            outline: none;
                            border: 1px solid #2e3188;
                        }
                    }
                    .keywrd_pop_topS{
                        position: absolute;
                        top: 40px;
                        left: 0px;
                        width: 100%;
                        background: #fff;
                        border: 1px solid #ddd;
                        border-radius: 3px;
                        padding: 10px;
                        z-index: 999;
                        box-shadow: 0px 10px 10px #00000029;
                        .keywrd_pop_topS_item{
                            padding: 5px 0px;
                            border-bottom: 1px solid #ddd;
                            &:last-child{
                                border-bottom: none;
                            }
                            .keywrd_pop_topS_item_link{
                                color: #333;
                                font-size: 14px;
                                font-weight: 400;
                                &:hover{
                                    color: #2e3188;
                                }
                            }
                        }
                    }
                }
            }
            .textbox{
                position: relative;
                .top-lbl{
                    ${'' /* position: absolute;
                    top: -10px;
                    left: 0px; */}
                    font-size: 12px;
                    color: #999;
                    font-weight: 700;
                }
                input{
                    width: 100%;
                    height: 36px;
                    border: 1px solid #ddd;
                    border-radius: 3px;
                    padding: 0px 10px;
                    font-size: 14px;
                    color: #333;
                    font-weight: 400;
                    &:focus{
                        outline: none;
                        border: 1px solid #2e3188;
                    }
                }
            }
            .button{
                width: 100%;
                height: 35px;
                padding: 7px 10px;
                border: none;
                border-radius: 3px;
                background: #2e3188;
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                cursor: pointer;
                &:hover{
                    background: #fff;
                    color: #2e3188;
                    border: 1px solid #2e3188;
                }
            }
            .icon-doublearrow{
                display: flex;
                align-items: center;
                svg{
                    padding-top: 16px;
                    cursor: pointer;
                }
            }
        }
        svg{
            font-size: 26px;
            cursor: pointer;
        }
    }
    @media (max-width: 767px) {
      .modify-sec-onward{
        padding: 15px !important;
        align-items: center;
      }
      .modify-sec-onward .jour-info .search_detls_div .modify-button{
        font-size: 14px;
        padding: 3px 11px;
        font-weight: 600;
      }
      .modify-sec-onward .jour-info{
        display: flex;
        width: 100%;
        justify-content: left;
        gap: 50px;
        .search_detls_div{
            ${'' /* display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-content: space-around;
            width: 100%;
            justify-content: flex-start;
            gap: 10px; */}
            display: flex;
            flex-flow: wrap;
            place-content: space-between;
            width: 100%;
            -webkit-box-pack: start;
            row-gap: 12px;
            column-gap: 10px;
          span.src{
            flex: 41%;
            text-align: left;
          }
          svg.fa-arrow-right{
            flex: 10%;
            text-align: center;
          }
          span.dst{
            flex: 41%;
            text-align: right;
          }
        }
      }
    }

`;
export default BusList
import React from 'react';
import HomeBanner from './home/HomeBanner'
const NotFoundPage = () => {
    return (
        <>
            <HomeBanner/>
        </>
    );
}

export default NotFoundPage;

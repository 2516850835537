import React from 'react';
import { useLocation } from 'react-router-dom';

import { AppProvider } from "./context/BusContext";
import { FilterContextProvider } from "./context/FilterContext";

function LocationWrapper({ children }) {
  const location = useLocation();
  return (
    <AppProvider url={location.pathname}>
      <FilterContextProvider>
        {children}
      </FilterContextProvider>
    </AppProvider>
  );
}

export default LocationWrapper;

import { createContext, useContext, useReducer, useEffect, useState } from "react";
import { useBusContext } from "./BusContext";
import reducer from "../reducer/FilterReducer";

const FilterContext = createContext();
  const initialFilterState = {
    filter_buses: [],
    all_buses: [],
    total_buses: 0,
    loading : false,
    errorIs : true,
    errormsg : '',
    filters: {
      text: "",
      bustypesAry : []
    },
  };
export const FilterContextProvider = ({ children }) => {
  const { buses, isLoading, isError, errorMsg } = useBusContext();
  
  const [state, dispatch] = useReducer(reducer, initialFilterState);
  //console.log('api',isLoading,isError,errorMsg);

  const updateFilterValue = (event) => {
    let name = event.target.name;
    let value = event.target.type === 'radio' ? event.target.value : event.target.value;
    return dispatch({ type: "UPDATE_FILTERS_VALUE", payload: { name, value } });
  };
  const updateBusType = (event) => {
    const name1 = event.target.name;
    const value = event.target.value;
    const isChecked = event.target.checked;
    return dispatch({ type: "UPDATE_BUSBTYPE_VALUE", payload: { name1, isChecked } });
  }
  const clearFilters = () => {
    dispatch({ 
      type: "CLEAR_FILTERS", 
      payload: buses, 
      isload:isLoading, 
      iseror:isError, 
      errmsg:errorMsg
    });
  };
  
  
  useEffect(() => {
    dispatch({ 
      type: "FILTER_BUSES",
      isload:isLoading, 
      iseror:isError,
      errmsg:errorMsg
    });
  }, [state.all_buses,state.filters]);
  

  useEffect(() => {
    dispatch({ 
      type: "LOAD_BUSES", 
      payload: buses, 
      isload:isLoading, 
      iseror:isError, 
      errmsg:errorMsg
    });
  }, [buses, isLoading, isError, errorMsg]);

  useEffect(() => {
    dispatch({ 
      type: "LOAD_BUSES", 
      payload: buses, 
      isload:isLoading, 
      iseror:isError, 
      errmsg:errorMsg
    });
  }, []);

  return (
    <FilterContext.Provider
      value={{
        ...state,
        updateFilterValue,
        updateBusType,
        clearFilters,
      }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};

import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
const AboutUs = () => {
  return (
    <>
        <HelmetProvider>
            <Helmet>
                <link rel="stylesheet" href="/css/aboutus.css" />
            </Helmet>
        </HelmetProvider>
        {/* <section class="about-banner-section">
            <img src="/images/banner-aa25a75b4376f1541ca192d626a100670ab2161d22fbbdb87b6d5dab1b98c513.jpg" alt=""/>
        </section> */}
        <section class="our-story-section">
            <div class="container-fluid">
                <h2>BUSPARTNER- Trustworthy Online Bus Ticket Booking Platform in Odisha</h2>
                <p>buspartner.in is the Odisha’s largest online bus booking system that provides hassle free bus booking experience. The platform provides information, pricing, availability, and booking facility for online bus ticket booking in Odisha. You can select your desired bus seats, bus type & bus operators. You can select from wide range of available buses like Volvo, Mercedes, AC Luxury, Deluxe, Standard, Sleeper and other Government buses. Choose your destination, select bus, check seat layout, select seats, and book your ticket in just a click. You have various online payment options like the debit/credit card, net-banking and also e-wallets. You can also do instant ticket cancellation, get refund easily & rebooking. Through our website buspartner.in, we offer travel related services i.e. Online Bus Ticket Booking, Bus Hiring and Online Bus Booking for Pilgrimage tours. buspartner.in is an excellent and trustable travel brand of India for bus booking. We are dedicated to ensure excellent user experience on our site and provide 24/7 customer support at every stage to all the passengers.</p>
            </div>
        </section>
        {/* <section class="aboutus-featured-section">
            <div class="container-fluid">
                <div class="aboutus-fatured-row">
                    <div class="af-left">
                        <h3>ABOUT US</h3>
                        <div class="af-inner">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos.</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos.</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos.</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos.</p>
                        </div>
                    </div>
                    <div class="af-right">
                        <img src="/images/Blue-Bus-PNG-Clipart-Background.png" alt=""/>
                    </div>
                </div>

                <div class="aboutus-fatured-row">
                    <div class="af-left">
                        <h3>What makes us unique</h3>
                        <div class="af-inner">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos.</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores suscipit corrupti, dolorem voluptatibus aliquid, minima voluptatum culpa eligendi quae harum sed iusto ullam hic ipsa illum quaerat praesentium architecto dignissimos</p>
                        </div>
                    </div>
                    <div class="af-right">
                        <img src="/images/Bus-PNG-Images-HD.png" alt=""/>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <div className='row'>
            <div className='col-md-7'>
            <section class="our-story-section">
                <div class="container-fluid">
                    <h2>Buspartner- Trustworthy Online Bus Ticket Booking Platform in Odisha</h2>
                    <p>buspartner.in is the Odisha’s largest online bus booking system that provides hassle free bus booking experience. The platform provides information, pricing, availability, and booking facility for online bus ticket booking in Odisha. You can select your desired bus seats, bus type & bus operators. You can select from wide range of available buses like Volvo, Mercedes, AC Luxury, Deluxe, Standard, Sleeper and other Government buses. Choose your destination, select bus, check seat layout, select seats, and book your ticket in just a click. You have various online payment options like the debit/credit card, net-banking and also e-wallets. You can also do instant ticket cancellation, get refund easily & rebooking. Through our website buspartner.in, we offer travel related services i.e. Online Bus Ticket Booking, Bus Hiring and Online Bus Booking for Pilgrimage tours. buspartner.in is an excellent and trustable travel brand of India for bus booking. We are dedicated to ensure excellent user experience on our site and provide 24/7 customer support at every stage to all the passengers.</p>
                </div>
            </section>
            </div>
            <div className='col-md-5 about_left_side'>
                <div className='about_left_side_under_fst'>
                    <div className='about_left_side_under_fst_img'></div>
                </div>
            </div>
        </div> */}
    </>
  )
}

export default AboutUs

import React,{useState,useEffect} from 'react'
import { useLocation, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from "axios";


import Helper from '../../util/Helper';

const Blogs = () => {
    
    const carouselOptions = {
        lazyLoad: true,
        loop: true,
        margin:25,
        responsiveClass: true,
        autoplay:true,
        autoplayTimeout:2500,
        autoplayHoverPause:true,
        autoheight: "true",
        mouseDrag: true,
        touchDrag: true,
        smartSpeed: 1000,
        nav: true,
        dots:false,
        responsive: {
            0: {
                items: 1
            },

            600: {
                items: 3
            },

            1024: {
                items: 3
            },

            1366: {
                items: 3
            }
        }
    };
    // const location = useLocation();
    // const slug = location.pathname.replace('/bus/', '');
    // const [fromSlug, destCityWithDateSlug] = slug.split('-to-');
    // const [toSlug, dateSlug] = destCityWithDateSlug.split('/');
    const { sourcedsest, optionaldoj=null, optionalbusid=null } = useParams();
    const [fromSlug, toSlug] = sourcedsest.split('-to-');
    console.log(sourcedsest,optionaldoj,optionalbusid);
    let slugdateSlug = optionaldoj
    const today = new Date();
    const dateSlug = slugdateSlug || `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
    
    const [busBlogs, setBusBlogs] = useState([]);
    const getBlogDetails = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const apiKey = process.env.REACT_APP_API_KEY;
        const apiToken = process.env.REACT_APP_API_TOKEN;
        try {
            const res =  await axios.get(apiUrl+'/bus/blog/'+fromSlug+'/'+toSlug, { 
                headers: {
                    'YESBUS_API_KEY': apiKey,
                    Authorization: `Bearer ${apiToken}`,
                }
            });
            const busBlog = await res.data;
            if(busBlog.status===200){
                setBusBlogs(busBlog.data);
            }else{
                setBusBlogs([]);
            }
        } catch (error) {
            setBusBlogs([]);
        }
    }
    useEffect(() => {
        getBlogDetails();
    }, []);
    useEffect(() => {
        getBlogDetails();
    }, [fromSlug,toSlug]);
    //console.log(busBlogs);
  return (
    <>
        {
            busBlogs &&  
            <BlogSectionStyle>
                
                <div className="blog_section_head">
                    <h1>{busBlogs.heading1}</h1>
                </div>
                <div className="blog_section_body">
                    <div className="blog-body" dangerouslySetInnerHTML={{ __html: busBlogs.sub1 }} />
                    <div className="blog-body">
                        <h3>{busBlogs.heading2}</h3>
                        <div dangerouslySetInnerHTML={{ __html: busBlogs.sub2 }} />
                    </div>
                    <div className="blog-body">
                        <h3>{busBlogs.heading3}</h3>
                        <div dangerouslySetInnerHTML={{ __html: busBlogs.sub3 }} />
                    </div>
                    <div className="blog-body">
                        <h3>{busBlogs.heading4}</h3>
                        <div dangerouslySetInnerHTML={{ __html: busBlogs.sub4 }} />
                    </div>
                </div>
                {
                    busBlogs.reverse_route && 
                    <>
                        <div className="blog_section_head">
                            <h2 className="links_h1">Bus from {toSlug} To {fromSlug}</h2>
                        </div>
                        <div className="blog_section_body">
                            <div className="blog-body">
                                <div className="links-container">
                                    <ul className="container1">
                                        {busBlogs.reverse_route.length > 0 &&
                                            busBlogs.reverse_route.map((rout, index) => 
                                                rout != null && (
                                                    <li className="linksm links1m" key={index}>
                                                        <Link to={'/bus/'+toSlug+'-to-'+fromSlug+'/'+dateSlug}>
                                                            <p>{toSlug} to {fromSlug} {rout} Bus </p>
                                                        </Link>
                                                    </li>
                                                )
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className="blog_images">
                        
                    <div className="gallery-container">
                        {
                            busBlogs.image1 && 
                            <div className="gallery-item" data-index="1">
                                <img src={busBlogs.image1} alt='' />
                            </div>
                        }
                        
                        {
                            busBlogs.image2 && 
                            <div className="gallery-item" data-index="2">
                                <img src={busBlogs.image2} alt=''/>
                            </div>
                        }
                        
                        {
                            busBlogs.image3 && 
                            <div className="gallery-item" data-index="3">
                                <img src={busBlogs.image3} alt='' />
                            </div>
                        }
                        {
                            busBlogs.image4 && 
                            <div className="gallery-item" data-index="4">
                                <img src={busBlogs.image4} alt='' />
                            </div>
                        }
                    </div>
                </div>
                
            </BlogSectionStyle>
        }
        
    </>
  )
}
const BlogSectionStyle = styled.div`
    margin-top: 30px;
    font-family: inherit;
    strong{
        color: rgb(46, 49, 136);
    }
    .blog_section_head {
        position: relative;
        text-align: center;
        padding-top: 20px;
    }
    .blog_section_head h1 {
        position: relative;
        display: inline-block;
        border-bottom: 5px solid rgb(217, 57, 50);
        color: #2f3183;
        padding-bottom: 10px;
        margin: 0 0 20px 0;
    }
    .blog_section_head h2 {
        position: relative;
        display: inline-block;
        border-bottom: 5px solid rgb(217, 57, 50);
        color: #2f3183;
        padding-bottom: 10px;
        margin: 0 0 20px 0;
    }
    .blog_section_body p {
        color: #000;
        letter-spacing: 0.1px;
        line-height: 25px;
        font-family: inherit;
        margin-bottom: 5px;
    }
    .blog_section_body h3 {
        position: relative;
        color: rgb(217, 57, 50);
        margin: 10px 0 10px;
        line-height: 26px;
        font-size : 21px;
    }
    .blog_section_body h3 strong{
        color: rgb(217, 57, 50);
        font-weight: 600;
    }
    .blog_section_body ol {
        list-style: square;
    }
    .blog_section_body ol li {
        padding-bottom: 6px;
    }
    .blog_section_body ol li::marker{
        color:rgb(46, 49, 136);
    }
    .blog_section_body ol li p{
        margin: 0px;
    }
    .blog-body a{
        color: rgb(217, 57, 50);
    }
    .blog-body a:hover{
        color: rgb(192 47 41);
    }
    .blog-body h2{
        position: relative;
        display: inline-block;
        border-bottom: 2px solid rgb(217, 57, 50);
        color: #2f3183;
        padding-bottom: 5px;
        margin: 0 0 10px 0;
        font-size: 21px;
    }
    .blog_images{
        margin-top: 30px;
        width: 100%;
    }
    .gallery-container{
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: flex-end;
    }
    .gallery-item{
        width:350px;
        height: 250px;
    }
    .gallery-item img{
        width: 350px;
        height: 250px;
        border-radius: 10px;
        border: 3px solid #2f3183;
        padding: 2px;
    }
    
    @media (max-width: 767px) {
        margin-top: 10px;
        .blog_section_head h2{
            font-size: 17px;
            border-bottom: 3px solid rgb(217, 57, 50) !important;
        }
        .blog_section_head h1{
            font-size: 17px;
            border-bottom: 3px solid rgb(217, 57, 50) !important;
        }
        .blog_section_head {
            padding-top: 0px;
        }
        .blog_section_body p {
            font-size: 13px;
        }
        .blog_section_body h3{
            font-size: 16px;
        }
        .blog_section_body ol li {
            font-size: 13px;
        }
        .blog-body h2 {
            font-size: 17px;
        }
        .gallery-container{
            flex-direction: column;
        }
        .gallery-item {
            width: auto;
            height: auto;
        }
    }
    
`;

export default Blogs
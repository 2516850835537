import React,{useState} from 'react'
import styled from 'styled-components'
import axios from 'axios';
import Helper from '../../util/Helper';
import { loadRazorpay } from '../../util/razorpay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faBusAlt, faCalendarAlt, faLock} from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
const Preview = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 767px)');
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [isCheckedTnc, setIsCheckedTnc] = useState(true);
    const location = useLocation();
    const bus_id = location.pathname.replace('/preview/', '');
    const bookingInfo = JSON.parse(localStorage.getItem('bookingDetails'));
    const bookingInfoData = bookingInfo[bus_id];
    const [choosePaymentMethod, setChoosePaymentMethod] = useState(1);

    const formattedDate = Helper.formatDate(bookingInfoData.journey_details.doj);
    const seatFareSum = bookingInfoData.seats.reduce((total, seat) => total + seat.seatFare, 0);
    // const seatBaseFareSum = bookingInfoData.seats.reduce((total, seat) => total + seat.seatBaseFare, 0);
    const seatBaseFareSum = bookingInfoData.seats.reduce((total, seat) => total + parseFloat(seat.seatBaseFare), 0);
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiToken = process.env.REACT_APP_API_TOKEN;
    const currentHostname = window.location.hostname;
    const hasWWW = currentHostname.includes('www');
    const updatedHostname = hasWWW ? currentHostname : `www.${currentHostname}`;
    console.log(currentHostname);
    const [loading, setLoading] = useState(false);
    const handlePayment = async () => {
        setIsPaymentLoading(true);
        const bookingFormData = {
            bus_id: bus_id,
            service_provider_id: bookingInfoData.service_provider_id,
            busof : 1,
            seats : bookingInfoData.seats,
            journey_details : bookingInfoData.journey_details,
            passanger : bookingInfoData.passanger,
            total_fare : seatFareSum,
            other_charges : seatFareSum-seatBaseFareSum,
            domain_name : updatedHostname,
            payment_type : choosePaymentMethod,
        };
        axios.post(apiUrl+'/booking/seat', bookingFormData , { 
            headers: {
              'YESBUS_API_KEY': apiKey,
              Authorization: `Bearer ${apiToken}`,
            }
        })
        .then((response) => {
            if(response.data.status=='201'){
                //console.log(process.env.PAYMENT_URL);
                if(choosePaymentMethod==2){
                    //redirect to razor pay payment link
                    localStorage.setItem('bookingId',response.data.data.booking_id);
                    localStorage.setItem('orderId',response.data.data.order_id);
                    callRazorpayToPayment(response.data.data.booking_id,response.data.data.order_id);
                }else{
                    localStorage.setItem('bookingCode',response.data.data.booking_code);
                    //window.location.replace = process.env.PAYMENT_URL+'initializepayment.php?initialize_payment='+response.data.data.booking_code+'&domain_name='+currentHostname;
                    window.location.href = 'https://payment.busagents.co.in//initializepayment.php?initialize_payment=' + response.data.data.booking_code + '&domain_name=' + currentHostname;
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    const callRazorpayToPayment = async (bookingId,orderId) => {
        // console.log(bookingId,orderId,seatFareSum*100);
        // const razorpayApiKey = 'rzp_test_Odk6sn1XEsOVTy'; // Replace with your actual API key
        const razorpayApiKey = 'rzp_live_i4P5LvzTyxH6R9';
        try {
          await loadRazorpay(razorpayApiKey);
          setIsPaymentLoading(false);
          //const orderid = orderId;
          // Use the Razorpay instance to initiate payment
          const razorpayOptions = {
            key: 'rzp_live_i4P5LvzTyxH6R9',
            amount: Math.ceil(seatFareSum * 100), // Amount in paise or the smallest currency unit
            currency: 'INR',
            name: 'Buspartner',
            description: 'Payment for bus seat booking',
            order_id: orderId,
            image: 'https://buspartner.in/images/yesbuslogo.png',
            // callback_url: 'ticket',
            handler: function (response) {
                // Handle the payment success or failure
                console.log(response);
                if(response.razorpay_payment_id!=''){
                    const bookingFormData = {
                        booking_id : bookingId,
                        razorpay_payment_id : response.razorpay_payment_id,
                        payment_order_id : response.razorpay_order_id,
                        payment_signature : response.razorpay_signature,
                        bus_id : bus_id,
                        route_id : bookingInfoData.journey_details.route_id,
                        doj : bookingInfoData.journey_details.doj,
                        seats : bookingInfoData.seats,
                        payment_type : 2,
                    };
                    setLoading(true);
                    axios.post(apiUrl+'/booking/update-seat-confirmation', bookingFormData , { 
                        headers: {
                          'YESBUS_API_KEY': apiKey,
                          Authorization: `Bearer ${apiToken}`,
                        }
                    })
                    .then((res) => {
                        localStorage.removeItem('bookingDetails');
                        localStorage.removeItem('bookingId');
                        localStorage.removeItem('orderId');
                        if(res.data.status=='200'){
                            // navigate('/'+razorpayOptions.callback_url+'/'+res.data.data.pnr_no);
                            navigate('/ticket/'+res.data.data.pnr_no);
                            // navigate('https://buspartner.in/ticket/'+res.data.data.pnr_no);
                            setTimeout(function(){
                                setLoading(false);
                            }, 3000);
                        }else if(res.data.status=='400'){
                            setLoading(false);
                            alert(res.data.message);
                            navigate('/');
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.log(error);
                    });
                    //navigate('/'+razorpayOptions.callback_url+'/'+bookingId);
                }else{
                    //navigate('/seat-booking-failed');
                }
            },
            prefill: {
                name: bookingInfoData.passanger.fullName,
                email: bookingInfoData.passanger.emailId,
                //contact: '6370420028',
                contact: bookingInfoData.passanger.phoneNumber,
            },
            theme: {
              color: 'rgb(46, 49, 141)',
            },
          };
          const razorpayInstance = new window.Razorpay(razorpayOptions);
          razorpayInstance.open();
        } catch (error) {
            setLoading(false);
          console.error('Failed to load Razorpay:', error);
        }
    }
    
  return (
    <PreviewMain>
        <section className='preview_sec'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        {
                            loading === true ? (
                                <div className='loader_div'>
                                    <div className="loader" style={{textAlign : 'center'}}>
                                        {/* <h2>Please wait....... </h2> */}
                                        <img src={process.env.PUBLIC_URL + '/images/bg-loading.gif'} alt="" />
                                        <p style={{fontSize : '20px'}}>Generating your Ticket. Please wait.......</p>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className='preview_head'>
                                        <div className="first_step">
                                            <Link to={`/bus/${bookingInfoData.journey_details.source}-to-${bookingInfoData.journey_details.destination}/${bookingInfoData.journey_details.doj}`}>
                                                <div className="new_site_ribbon">Passanger Details</div>
                                            </Link>
                                        </div>
                                        <div className="second_step">
                                            <div className="new_site_ribbon active">Review Details</div>
                                        </div>
                                    </div>
                                    <div className='preview_body'>
                                        <div className='journey_details borderbox'>
                                            <div className="journey_details_head">
                                                <h4>Journey Details</h4>
                                            </div>
                                            <div className="journey_details_body">
                                                <div className='journey'>
                                                    <h5>
                                                        <FontAwesomeIcon icon={faMapMarkerAlt} className="fa-icon" /><span>{bookingInfoData.journey_details.source}</span>
                                                    </h5>
                                                    <h6>{bookingInfoData.journey_details.boarding_point} - {bookingInfoData.journey_details.boarding_time}</h6>
                                                </div>
                                                <div className='journey'>
                                                    <h5>
                                                        <FontAwesomeIcon icon={faMapMarkerAlt} className="fa-icon" /><span>{bookingInfoData.journey_details.destination}</span>
                                                    </h5>
                                                    <h6>{bookingInfoData.journey_details.dropping_point} - {bookingInfoData.journey_details.dropping_time}</h6>
                                                </div>
                                                <div className='journey'>
                                                    <h5>
                                                        <FontAwesomeIcon icon={faBusAlt} className="fa-icon" /><span>{bookingInfoData.journey_details.bus_name}</span>
                                                    </h5>
                                                </div>
                                                <div className='journey'>
                                                    <h5>
                                                        <FontAwesomeIcon icon={faCalendarAlt} className="fa-icon" /><span>{formattedDate}</span>
                                                    </h5>
                                                    <h6>{bookingInfoData.seats.length} Passenger(s)</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='passanger_details borderbox'>
                                            <div className="passanger_details_head">
                                                <h4>Passenger Details</h4>
                                            </div>
                                            <div className='passanger_details_body'>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Passenger Name</th>
                                                            <th>Seat No</th>
                                                            <th>Gender</th>
                                                            <th>Age</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            bookingInfoData.seats.map((seat) => (
                                                                <tr key={seat.seatName}>
                                                                    <td>{bookingInfoData.passanger[`passanger_${seat.seatName}_name`]}</td>
                                                                    <td>{seat.seatName}</td>
                                                                    <td>
                                                                    {
                                                                        bookingInfoData.passanger[`passanger_${seat.seatName}_gender`]==1
                                                                        ? 'Female' : bookingInfoData.passanger[`passanger_${seat.seatName}_gender`]==0 ? 'Male' : 'Other' 
                                                                    }
                                                                    </td>
                                                                    <td>{bookingInfoData.passanger[`passanger_${seat.seatName}_age`]}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="contact_details">
                                                <div className="contact_details_head">
                                                    <h4>Contact Details <span>#</span><label>will receive booking details in this mobile number & email address</label></h4>
                                                </div>
                                                <div className="passanger_details_body">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                {/* <th>Full Name</th> */}
                                                                <th>Mobile Number</th>
                                                                <th>Email Address</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                {/* <td>{bookingInfoData.passanger.fullName}</td> */}
                                                                <td>+{`${bookingInfoData.passanger.countryCode} ${bookingInfoData.passanger.phoneNumber}`}</td>
                                                                <td>{bookingInfoData.passanger.emailId}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='fare_details borderbox'>
                                            {/* <div className="fare_details_head">
                                                <h4>Fare Details</h4>
                                            </div> */}
                                            <div className='fare_details_body'>
                                                <div className='fare_sec'>
                                                    <div className='fare_details_sec'>
                                                        <div className='base_fare'>
                                                            <div className='fare_lable'>
                                                                <h5>Base Fare</h5>
                                                            </div>
                                                            <div className='fare_amnt'>
                                                                <h5>{Helper.formatAsIndianRupee(seatBaseFareSum)}</h5>
                                                            </div>
                                                        </div>
                                                        <div className='base_fare'>
                                                            <div className='fare_lable'>
                                                                <h5>Other Charges</h5>
                                                            </div>
                                                            <div className='fare_amnt'>
                                                                <h5>+ {Helper.formatAsIndianRupee(seatFareSum-seatBaseFareSum)}</h5>
                                                            </div>
                                                        </div>
                                                        <div className='base_fare bordertop'>
                                                            <div className='fare_lable'>
                                                                <h5>Total Fee</h5>
                                                            </div>
                                                            <div className='fare_amnt'>
                                                                <h5>{Helper.formatAsIndianRupee(seatFareSum)}</h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className='base_fare'>
                                                            <div className='tnc_sec'>
                                                                <label htmlFor="">
                                                                    <input type="checkbox" name="" defaultChecked={isCheckedTnc} onChange={()=>setIsCheckedTnc(!isCheckedTnc)} /> I Agree That I Have Read <a href="">Terms & Conditions</a> And The <a href="">Privacy Policy</a>
                                                                </label>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    {/* <div className='payment_btn_div desk_mode'>
                                                        <button className='btn' disabled={!isCheckedTnc} onClick={handlePayment}><FontAwesomeIcon icon={faLock} className="fa-icon" />{isPaymentLoading ? 'Processing...' : 'Click To Make Payment'} <h6>100% Secure & Easy Payment</h6></button>
                                                    </div> */}
                                                </div>
                                                <div className='fare_sec fare_sec_btm'>
                                                    <div className='fare_details_sec'>
                                                        <div className='payment_method_div'>
                                                            <div className='payment_method_div'>
                                                                <input type="radio" name="payment_method" id="payment_method_phonepe" onClick={()=>setChoosePaymentMethod(1)} defaultChecked={choosePaymentMethod==1 ? true : false} />
                                                                <label htmlFor="payment_method_phonepe"><img src={process.env.PUBLIC_URL + '/images/phonepe_logo.png'} alt="" style={{width:'100px'}} /></label>
                                                            </div>
                                                            {/* <div className='payment_method_div'>
                                                                <input type="radio" name="payment_method" id="payment_method_razorpay" onClick={()=>setChoosePaymentMethod(2)} defaultChecked={choosePaymentMethod==2 ? true : false} />
                                                                <label htmlFor="payment_method_razorpay"><img src={process.env.PUBLIC_URL + '/images/Razorpay_logo.svg'} alt="" style={{width:'100px'}} /></label>
                                                            </div> */}
                                                        </div>
                                                        <div className='base_fare'>
                                                            <div className='tnc_sec'>
                                                                <label htmlFor="">
                                                                    <input type="checkbox" name="" defaultChecked={isCheckedTnc} onChange={()=>setIsCheckedTnc(!isCheckedTnc)} /> I Agree That I Have Read <a href="">Terms & Conditions</a> And The <a href="">Privacy Policy</a>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='payment_btn_div desk_mode'>
                                                        <button className='btn' disabled={!isCheckedTnc} onClick={handlePayment}><FontAwesomeIcon icon={faLock} className="fa-icon" />{isPaymentLoading ? 'Processing...' : 'Click To Make Payment'} <h6>100% Secure & Easy Payment</h6></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
        {
            isMobile &&
            <div className='mob_payment_btn_div'>
                <button className='btn' onClick={handlePayment} disabled={!isCheckedTnc}><FontAwesomeIcon icon={faLock} className="fa-icon" />{isPaymentLoading ? 'Processing...' : 'Click To Make Payment'} <h6>100% Secure & Easy Payment</h6></button>
            </div> 
        }
        
    </PreviewMain>
  )
}
const PreviewMain = styled.div`
    padding: 20px 0px;
    .mob_payment_btn_div{
        position: fixed;
        bottom: 10px;
        background: #2f3188;
        width: 100%;
        text-align: center;
        button{
            background: rgb(45, 49, 141);
            border: none;
            border-radius: 1px;
            font-size: 22px;
            padding: 7px 14px;
            color: rgb(255, 255, 255);
            svg{
                padding-right: 10px;
                position: relative;
                font-size: 30px;
                top: 10px;
            }
            h6{
                font-size: 11px;
                color: rgb(217, 57, 50);
                margin-bottom: 0px;
            }
        }
    }
    .preview_sec{
        background-color: #fff;
        .preview_head{
            text-align: center;
            color: #2e318d;
            height: 76px;
            margin-left: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr 2fr;
            .first_step{
                .new_site_ribbon{
                    position: absolute;
                    height: 51px;
                    min-width: 75px;
                    background: #858484;
                    padding: 8px 38px;
                    color: rgb(255, 255, 255);
                    font-size: 22px;
                    text-align: center;
                    ::before {
                        content: "";
                        width: 0px;
                        height: 0px;
                        border-width: 26px 11px;
                        border-style: solid;
                        border-color: #858484 #858484 #858484 transparent;
                        position: absolute;
                        left: -12px;
                        top: 0px;
                    }
                    ::after {
                        position: absolute;
                        content: "";
                        width: 0px;
                        height: 0px;
                        border-left: 20px solid #858484;
                        border-top: 27px solid transparent;
                        border-bottom: 25px solid transparent;
                        right: -20px;
                        top: 0px;
                    }
                }
            }
            .second_step{
                .new_site_ribbon{
                    position: absolute;
                    height: 51px;
                    min-width: 75px;
                    background: rgb(46 49 141);
                    padding: 8px 28px;
                    color: rgb(255, 255, 255);
                    font-size: 22px;
                    text-align: center;
                    ::before {
                        content: "";
                        width: 0px;
                        height: 0px;
                        border-width: 26px 11px;
                        border-style: solid;
                        border-color: rgb(46 49 141) rgb(46 49 141) rgb(46 49 141) transparent;
                        position: absolute;
                        left: -12px;
                        top: 0px;
                    }
                    ::after {
                        position: absolute;
                        content: "";
                        width: 0px;
                        height: 0px;
                        border-left: 20px solid rgb(46 49 141);
                        border-top: 27px solid transparent;
                        border-bottom: 25px solid transparent;
                        right: -20px;
                        top: 0px;
                    }
                }
            }
        }
        .preview_body{
            .borderbox{
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 10px;
                border: 1px solid rgb(214, 214, 214);
                background: rgba(253, 253, 253, 0.8);
                padding: 15px;
            }
            .journey_details{
                .journey_details_head{
                    h4{
                        color: rgb(47, 49, 131);
                        font-size: 20px;
                        font-weight: 700;
                        text-transform: capitalize;
                        padding-bottom: 10px;
                        border-bottom: 2px solid rgb(217 57 50);
                    }
                }
                .journey_details_body{
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    grid-column-gap: 20px;
                    padding-top: 20px;
                    .journey{
                        border-right: 1px solid #ddd;
                        padding-left: 10px;
                        h5{
                            svg{
                                color: rgb(217 57 50);
                            }
                            span{
                                padding-left: 10px;
                                font-size: 18px;
                                text-transform: capitalize;
                            }
                        }
                        h6{
                            font-size: 14px;
                        }
                    }
                    .journey:last-child{
                        border-right: none;
                    }
                }
            }
            .passanger_details{
                margin-top: 40px;
                .passanger_details_head{
                    h4{
                        color: rgb(47, 49, 131);
                        font-size: 20px;
                        font-weight: 700;
                        text-transform: capitalize;
                        padding-bottom: 10px;
                        border-bottom: 2px solid rgb(217 57 50);
                    }
                }
                .passanger_details_body{
                    padding-top: 20px;
                    table{
                        width: 100%;
                        border-collapse: collapse;
                        thead{
                            tr{
                                th{
                                    padding: 5px;
                                    border: 1px solid rgb(233 233 233);
                                    text-align: left;
                                    color: #b2adad;
                                    font-size: 17px;
                                    font-weight: 700;
                                }
                                
                            }
                        }
                        tbody{
                            tr{
                                td{
                                    padding: 5px;
                                    border: 1px solid rgb(233 233 233);
                                    text-align: left;
                                    font-size: 15px;
                                    font-weight: 600;
                                    color: rgb(47, 49, 131);
                                }
                            }
                        }
                    }
                }
                .contact_details{
                    margin-top: 40px;
                    .contact_details_head{
                        h4{
                            color: rgb(47, 49, 131);
                            font-size: 20px;
                            font-weight: 700;
                            text-transform: capitalize;
                            padding-bottom: 10px;
                            border-bottom: 2px solid rgb(217 57 50);
                            
                            span{
                                padding-left: 20px;
                                color: rgb(217, 57, 50);
                                font-size: 20px;
                                padding-right: 4px;
                            }
                            label{
                                font-size: 12px;
                                color: gray;
                                position: relative;
                                top: -2px;
                            }
                        }
                    }
                    .contact_details_body{
                        padding-top: 5px;
                        table{
                            width: 70%;
                            border-collapse: collapse;
                            thead{
                                tr{
                                    th{
                                        padding: 5px;
                                        border: none;
                                        text-align: left;
                                        color: #b2adad;
                                        font-size: 17px;
                                        font-weight: 700;
                                    }
                                }
                            }
                            tbody{
                                tr{
                                    td{
                                        padding: 5px;
                                        border: none;
                                        text-align: left;
                                        font-size: 15px;
                                        font-weight: 600;
                                        color: rgb(47, 49, 131);
                                    }
                                }
                            }

                    }
                }
            }
        }
        .fare_details{
            margin-top: 40px;
            .fare_details_body{
                .fare_sec_btm{
                    .fare_details_sec{
                        .payment_method_div{
                            display: flex;
                            gap: 8px;
                            align-items: center;
                            input[type="radio"]{
                                appearance: none;
                                width: 20px;
                                height: 20px;
                                border: 1px solid rgb(46 49 136);
                                border-radius: 50%;
                                outline: none;
                                box-shadow: 0 0 5px rgb(46 49 136);
                                transition: box-shadow 0.3s ease;
                            }
                            input[type='radio']:before {
                                content: '';
                                display: block;
                                width: 60%;
                                height: 60%;
                                margin: 20% auto;
                                border-radius: 50%;
                            }
                            input[type='radio']:checked:before {
                                background: rgb(46 49 136);
                            }
                        }
                    }
                }
                .fare_sec{
                    display: flex;
                    justify-content: space-between;
                    align-items: end;
                    .fare_details_sec{
                        width: 50%;
                        .base_fare{
                            display: flex;
                            justify-content: space-between;
                            .fare_lable{
                                h5{
                                    color: #676767;
                                    font-size: 17px;
                                }
                            }
                            .fare_amnt{
                                h5{
                                    color: #676767;
                                    font-size: 17px;
                                }
                            }
                            .tnc_sec{
                                label{
                                    font-size: 14px;
                                    font-weight: 600;
                                    color: goldenrod;
                                }
                            }
                        }
                        .bordertop{
                            border-top: 2px solid #000;
                            margin: 10px 0px;
                            padding: 10px 0px;
                        }
                    }
                    .payment_btn_div{
                        button{
                            background: rgb(45, 49, 141);
                            border: none;
                            border-radius: 1px;
                            font-size: 22px;
                            padding: 7px 14px;
                            color: rgb(255, 255, 255);
                            svg{
                                padding-right: 10px;
                                position: relative;
                                font-size: 30px;
                                top: 10px;
                            }
                            h6{
                                font-size: 11px;
                                color: rgb(217, 57, 50);
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
            
        }
    }
    
    
    }
    @media (min-width:250px) and (max-width:452px) {
        .preview_sec .preview_body .journey_details .journey_details_body{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .preview_sec .preview_body .journey_details .journey_details_body .journey{
            padding-left:0px;
            border-right: none;
        }
        .preview_sec .preview_body .journey_details .journey_details_body .journey h5 span{
            font-size: 16px;
        }
        .preview_sec .preview_body .journey_details .journey_details_head h4{
            font-size: 15px;
            padding-bottom: 5px;
        }
        .preview_sec .preview_body .passanger_details .passanger_details_head h4{
            font-size: 15px;
            padding-bottom: 5px;
        }
        .preview_sec .preview_body .passanger_details .passanger_details_body{
            padding-top: 10px;
        }
        .preview_sec .preview_body .passanger_details .passanger_details_body table thead tr th{
            font-size: 12px;
            padding: 3px;
        }
        .preview_sec .preview_body .passanger_details .passanger_details_body table tbody tr td{
            font-size: 12px;
            padding: 3px;
        }
        .preview_sec .preview_body .passanger_details .contact_details{
            margin-top: 15px;
        }
        .preview_sec .preview_body .passanger_details .contact_details .contact_details_head h4{
            font-size: 16px;
            padding-bottom: 5px;
        }
        .preview_sec .preview_body .passanger_details .contact_details .contact_details_head h4 label{
            font-size: 11px;
        }
        .preview_sec .preview_body .contact_details .passanger_details_body table thead tr th{
            border: none;
        }
        .preview_sec .preview_body .contact_details .passanger_details_body table tbody tr td{
            border: none;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec{
            flex-direction: column;
            gap: 30px;
            align-items: center;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec .fare_details_sec{
            width: 100%;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec .fare_details_sec .base_fare .fare_lable h5{
            font-size: 14px;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec .fare_details_sec .base_fare .fare_amnt h5{
            font-size: 14px;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec .fare_details_sec .base_fare .tnc_sec label{
            font-size: 12px;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec .payment_btn_div button{
            font-size: 17px;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec .payment_btn_div button svg{
            font-size: 25px;
        }
        .preview_sec .preview_body .passanger_details{
            margin-top: 20px;
        }
        .preview_sec .preview_body .fare_details{
            margin-top: 20px;
            margin-bottom: 95px;
        }
        .preview_sec .preview_head{
            display: flex;
            justify-content: space-around;
            height: 60px;
            margin-left:0px;
        }
        .preview_sec .preview_head .first_step .new_site_ribbon{
            height: 40px;
            font-size: 15px;
            padding: 10px 10px;
            position: relative;
        }
        .preview_sec .preview_head .first_step .new_site_ribbon::before{
            border-width: 20px 10px;
            position: absolute;
            left: -19px;
            top: 0px;
        }
        .preview_sec .preview_head .first_step .new_site_ribbon::after{
            border-left: 20px solid rgb(133, 132, 132);
            border-top: 20px solid transparent;
            border-bottom: 21px solid transparent;
        }
        .preview_sec .preview_head .second_step .new_site_ribbon{
            position: relative;
            padding: 10px 10px;
            height: 40px;
            font-size: 15px;
        }
        .preview_sec .preview_head .second_step .new_site_ribbon::before{
            border-width: 20px 15px;
            position: absolute;
            left: -19px;
            top: 0px;
        }
        .preview_sec .preview_head .second_step .new_site_ribbon::after{
            border-left: 20px solid rgb(46, 49, 141);
            border-top: 20px solid transparent;
            border-bottom: 21px solid transparent;
        }
    }
    @media (min-width:451px) and (max-width:767px){
        .preview_sec .preview_body .journey_details .journey_details_body{
            display: grid;
            justify-content: space-between;
            align-items: flex-start;
            grid-template-columns: 1fr 1fr;
            gap: 20px 0px;
        }
        .preview_sec .preview_body .journey_details .journey_details_body .journey{
            padding-left:0px;
            border-right: none;
        }
        .preview_sec .preview_body .journey_details .journey_details_body .journey h5 span{
            font-size: 15px;
        }
        .preview_sec .preview_body .journey_details .journey_details_head h4{
            font-size: 16px;
            padding-bottom: 6px;
        }
        .preview_sec .preview_body .passanger_details .passanger_details_head h4{
            font-size: 16px;
            padding-bottom: 6px;
        }
        .preview_sec .preview_body .passanger_details .passanger_details_body{
            padding-top: 10px;
        }
        .preview_sec .preview_body .passanger_details .passanger_details_body table thead tr th{
            font-size: 14px;
            padding: 5px;
        }
        .preview_sec .preview_body .passanger_details .passanger_details_body table tbody tr td{
            font-size: 14px;
            padding: 5px;
        }
        .preview_sec .preview_body .passanger_details .contact_details{
            margin-top: 15px;
        }
        .preview_sec .preview_body .passanger_details .contact_details .contact_details_head h4{
            font-size: 16px;
            padding-bottom: 6px;
        }
        .preview_sec .preview_body .passanger_details .contact_details .contact_details_head h4 label{
            font-size: 12px;
        }
        .preview_sec .preview_body .contact_details .passanger_details_body table thead tr th{
            border: none;
        }
        .preview_sec .preview_body .contact_details .passanger_details_body table tbody tr td{
            border: none;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec{
            flex-direction: column;
            gap: 30px;
            align-items: end;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec .fare_details_sec{
            width: 100%;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec .fare_details_sec .base_fare .fare_lable h5{
            font-size: 16px;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec .fare_details_sec .base_fare .fare_amnt h5{
            font-size: 16px;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec .fare_details_sec .base_fare .tnc_sec label{
            font-size: 16px;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec .payment_btn_div button{
            font-size: 18px;
        }
        .preview_sec .preview_body .fare_details .fare_details_body .fare_sec .payment_btn_div button svg{
            font-size: 28px;
        }
        .preview_sec .preview_body .passanger_details{
            margin-top: 25px;
        }
        .preview_sec .preview_body .fare_details{
            margin-top: 25px;
        }
        .preview_sec .preview_head{
            display: flex;
            justify-content: left;
            gap:70px;
            height: 60px;
        }
        .preview_sec .preview_head .first_step .new_site_ribbon{
            height: 40px;
            font-size: 16px;
            padding: 7px 17px;
            position: relative;
        }
        .preview_sec .preview_head .first_step .new_site_ribbon::before{
            border-width: 20px 10px;
            position: absolute;
            left: -19px;
            top: 0px;
        }
        .preview_sec .preview_head .first_step .new_site_ribbon::after{
            border-left: 20px solid rgb(133, 132, 132);
            border-top: 20px solid transparent;
            border-bottom: 21px solid transparent;
        }
        .preview_sec .preview_head .second_step .new_site_ribbon{
            position: relative;
            padding: 7px 17px;
            height: 40px;
            font-size: 16px;
        }
        .preview_sec .preview_head .second_step .new_site_ribbon::before{
            border-width: 20px 10px;
            position: absolute;
            left: -19px;
            top: 0px;
        }
        .preview_sec .preview_head .second_step .new_site_ribbon::after{
            border-left: 20px solid rgb(46, 49, 141);
            border-top: 20px solid transparent;
            border-bottom: 21px solid transparent;
        }
    }
    @media (min-width:767px) and (max-width:990px) {
        .preview_sec .preview_body .journey_details .journey_details_body{
            display: flex;
            align-items: flex-start;
            justify-content: space-around;
            gap: 35px 20px;
            justify-content: space-between;
            flex-flow: wrap;
        }
        .preview_sec .preview_body .journey_details .journey_details_body .journey{
            padding-left: 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: baseline;
            border-right: none;
        }
        .preview_sec .preview_body .journey_details .journey_details_body .journey h5{
            display: flex;
            gap: 5px;
        }
        .preview_sec .preview_body .journey_details .journey_details_body .journey h5 span{
            padding-left: 0px;
        }
        .preview_sec .preview_head{
            display: flex;
            justify-content: left;
            gap:70px;
            height: 60px;
        }
        .preview_sec .preview_head .first_step .new_site_ribbon{
            height: 40px;
            font-size: 18px;
            padding: 7px 17px;
            position: relative;
        }
        .preview_sec .preview_head .first_step .new_site_ribbon::before{
            border-width: 20px 10px;
            position: absolute;
            left: -19px;
            top: 0px;
        }
        .preview_sec .preview_head .first_step .new_site_ribbon::after{
            border-left: 20px solid rgb(133, 132, 132);
            border-top: 20px solid transparent;
            border-bottom: 21px solid transparent;
        }
        .preview_sec .preview_head .second_step .new_site_ribbon{
            position: relative;
            padding: 7px 17px;
            height: 40px;
            font-size: 18px;
        }
        .preview_sec .preview_head .second_step .new_site_ribbon::before{
            border-width: 20px 10px;
            position: absolute;
            left: -19px;
            top: 0px;
        }
        .preview_sec .preview_head .second_step .new_site_ribbon::after{
            border-left: 20px solid rgb(46, 49, 141);
            border-top: 20px solid transparent;
            border-bottom: 21px solid transparent;
        }
        
    }
`;

export default Preview
import React from 'react'
import styled from "styled-components";
const CustomerRatings = () => {
  return (
    <CustomerRatingsSection>
        <div className="container-fluid">
            <div className="row">
                <h3 className="title-section">Our Ratings</h3>
                <div className="success_story_sec_new_body_sec_new">
                    <div className="success_story_sec_new_body_sec_new_inner_card_sec">
                        <div className="success_story_sec_new_single">
                            <img src="https://img1.picmix.com/output/stamp/normal/9/5/7/0/340759_76d74.gif" className="st_icn_new_sec" alt=""/>
                            <div className="success_story_sec_new_single_img">
                                <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80" alt=""/>
                            </div>
                            <div className="success_story_sec_new_single_cont">
                                <h6>Priyanka</h6>
                                <h5></h5>
                                <p>My experience was brilliant. All models were good and polite to work with.- Priyanka&nbsp;&nbsp;</p>
                                <h4>Got selected as a Make-up Artist through All Talent Agency</h4>
                            </div>
                        </div>
                        <div className="success_story_sec_new_single">
                            <img src="https://img1.picmix.com/output/stamp/normal/9/5/7/0/340759_76d74.gif" className="st_icn_new_sec" alt=""/>
                            <div className="success_story_sec_new_single_img">
                                <img src="https://img.freepik.com/premium-photo/photo-businesswoman_889227-37078.jpg" alt=""/>
                            </div>
                            <div className="success_story_sec_new_single_cont">
                                <h6>Aniket</h6>
                                <h5></h5>
                                <p>I have really great experience with client.. felt amazing worked with the client genuinely they are supportive and cooperative..- Aniket&nbsp;</p>
                                <h4>Got selected as a model for a brand shoot through All Talent Agency</h4>
                            </div>
                        </div>
                        <div className="success_story_sec_new_single">
                            <img src="https://img1.picmix.com/output/stamp/normal/9/5/7/0/340759_76d74.gif" className="st_icn_new_sec" alt=""/>
                            <div className="success_story_sec_new_single_img">
                                <img src="https://media.istockphoto.com/id/1307791658/photo/headshot-portrait-of-arabic-businessman-posing-in-office.jpg?s=612x612&w=0&k=20&c=V8Wqmr-x6KYXdPDV-iAi5pjJqgKGasOCRYUSNUNsltM=" alt=""/>
                            </div>
                            <div className="success_story_sec_new_single_cont">
                                <h6>Anjali</h6>
                                <h5></h5>
                                <p>The experience was nice. The shoot was good and the staff was coperative.&nbsp;- Anjali</p>
                                <h4>Got selected for a Brand Shoot through All Talent Agency</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </CustomerRatingsSection>
  )
}
const CustomerRatingsSection = styled.section`
    .title-section{
        text-align: center;
        font-weight: 600;
        position: relative;
        font-size: 35px;
        color: #000;
        font-family: 'Barlow Semi Condensed', sans-serif;
        margin-bottom: 15px;
        text-transform: capitalize;
    }
    .success_story_sec_new_body_sec_new {
        position: relative;
        margin-top: 25px;
        display: block;
    }
    .success_story_sec_new_body_sec_new_inner_card_sec {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
    }
    .success_story_sec_new_single {
        position: relative;
        background: #FFFFFF;
        box-shadow: 3.4467px 4.13604px 20.6802px rgba(0, 0, 0, 0.1);
        border-radius: 20.6802px;
        padding: 25px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        align-items: center;
    }
    .st_icn_new_sec {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 32px;
    }
    .success_story_sec_new_single_img {
        position: relative;
        z-index: 11;
    }
    .success_story_sec_new_single_img img {
        width: 100%;
        border-radius: 34.467px 3.4467px;
        height: 205px;
        object-fit: cover;
    }
    .play_icn_new {
        position: absolute;
        right: 0;
        left: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 111;
        top: 0;
        bottom: 0;
        width: 100%;
    }
    .play_icn_new img {
        width: 82px;
        height: 82px;
    }
    .success_story_sec_new_single_img:after {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        border: 2.75736px solid rgb(200, 71, 59);
        border-radius: 34.467px 3.4467px;
        left: -10px;
        top: -10px;
        z-index: -1;
    }
    .success_story_sec_new_single_cont h6 {
        position: relative;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.01em;
        color: rgb(46 47 127);
        margin-bottom: 10px;
    }
    .success_story_sec_new_single_cont h5 {
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 12px;
        letter-spacing: 0.01em;
        color: rgb(46 47 127);
        margin-bottom: 10px;
    }
    .success_story_sec_new_single_cont p {
        font-weight: 400;
        font-size: 13px;
        letter-spacing: 0.01em;
        color: #202020;
        display: block;
        margin: 20px 0;
    }
    .success_story_sec_new_single_cont h4 {
        position: relative;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.01em;
        color: rgb(46 47 127);
        margin-bottom: 0px;
    }

    @media (max-width: 767px) {
        .success_story_sec_new_body_sec_new_inner_card_sec {
            grid-template-columns: 1fr;
        }
        .st_icn_new_sec {
            width: 60px;
        }
    }
    ${'' /* @media (min-width:250px) and (max-width:390px) {
        .success_story_sec_new_body_sec_new_inner_card_sec {
            grid-template-columns: 1fr;
        }
    } */}
`;
export default CustomerRatings
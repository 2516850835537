import React from 'react'
import styled from "styled-components";
const Addrun = () => {
  return (
    <>
        <AddRunSection className="topSection desk_mode">
            <ul className="offerWrapper">
                <li className="wow fadeInUp" data-wow-duration="1.4s">
                    <span className="cf offer-li-wrap">
                        <a href="" className="offer-img">
                        <img src="/images/banner25122020-817.png"/>
                        </a>
                    </span>
                </li>
                <li className="wow fadeInUp" data-wow-duration="1.9s">
                    <span className="cf offer-li-wrap">
                        <a href="" className="offer-img">
                        <img src="/images/banner25122020-927.png"/>
                        </a>
                    </span>
                </li>
                <li className="wow fadeInUp" data-wow-duration="2.4s">
                    <span className="cf offer-li-wrap">
                        <a href="" className="offer-img">
                        <img src="/images/banner25122020-782.png"/>
                        </a>
                    </span>
                </li>
            </ul>
        </AddRunSection>
    </>
  )
}
const AddRunSection = styled.section`
    position: relative;
    top: -90px;
    .offerWrapper {
        display: flex;
        grid-gap: 35px;
        justify-content: center;
    }
    .offerWrapper img {
        border-radius: 20px;
        box-shadow: 0px 2px 12px #00000029;
    }
`;

export default Addrun
import React,{useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import axios from "axios";
import styled from 'styled-components';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
const OtherBlogs = () => {
    const { sourcedsest, optionaldoj=null, optionalbusid=null } = useParams();

    const [fromSlug, toSlug] = sourcedsest.split('-to-');
    const currentDomainname = window.location.hostname;
    const [busOtherBlogs, setOtherBusBlogs] = useState([]);
    const getOtherBlogDetails = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const apiKey = process.env.REACT_APP_API_KEY;
        const apiToken = process.env.REACT_APP_API_TOKEN;
        try {
            const res =  await axios.get(apiUrl+'/master/seo/'+currentDomainname+'/'+fromSlug+'/'+toSlug, { 
                headers: {
                    'YESBUS_API_KEY': apiKey,
                    Authorization: `Bearer ${apiToken}`,
                }
            });
            const busBlog = await res.data;
            if(busBlog.status===200){
                setOtherBusBlogs(busBlog.data);
            }else{
                setOtherBusBlogs([]);
            }
        } catch (error) {
            setOtherBusBlogs([]);
        }
    }

    useEffect(() => {
        getOtherBlogDetails();
    }, []);
    useEffect(() => {
        getOtherBlogDetails();
    }, [fromSlug,toSlug]);
  return (
    <>
        <OtherBlogSectionStyle>
            <h2 style={{textAlign:'center'}}>FAQS for {fromSlug} to {toSlug} bus ticket booking</h2>
            <div className='blog_faqs_div'>
                <div className="mt-4">
                    <MDBAccordion alwaysOpen initialActive={1}>
                        <MDBAccordionItem collapseId={1} headerTitle={`Is bus available from ${fromSlug} to ${toSlug} ?`}>
                        There are various buses available for bus travelling from <code>{fromSlug}</code> to <code>{toSlug}</code> including seater buses, Ac to Non AC sleeper, non-AC deluxe buses, AC deluxe buses and Volvo buses.
                        </MDBAccordionItem>

                        <MDBAccordionItem collapseId={2} headerTitle={`What are the boarding points in ${fromSlug} for ${fromSlug} to ${toSlug} bus?`}>
                        The common boarding points for <code>{fromSlug}</code> and <code>{toSlug}</code> are {busOtherBlogs.boarding_points}.
                        </MDBAccordionItem>

                        <MDBAccordionItem collapseId={3} headerTitle={`What are the dropping points in ${toSlug} for ${fromSlug} to ${toSlug} bus?`}>
                        The common dropping points for <code>{fromSlug}</code> and <code>{toSlug}</code> are {busOtherBlogs.dropping_points}.
                        </MDBAccordionItem>

                        <MDBAccordionItem collapseId={4} headerTitle={`What is the cheapest bus ticket fare for ${fromSlug} to ${toSlug} bus?`}>
                        The cheapest bus ticket fare for <code>{fromSlug} to {toSlug}</code> is <strong><code> INR {busOtherBlogs.bus_fare}</code></strong>.
                        </MDBAccordionItem>


                        <MDBAccordionItem collapseId={5} headerTitle={`What is the departure time of the first bus from ${fromSlug} to ${toSlug}?`}>
                        The departure time of the first bus from {fromSlug} to {toSlug} is <strong><code>{busOtherBlogs.first_bus_departure_time}</code></strong>.
                        </MDBAccordionItem>

                        <MDBAccordionItem collapseId={6} headerTitle={`What is the departure time of the last bus from ${fromSlug} to ${toSlug}?`}>
                        The departure time of the last bus from the {fromSlug} to {toSlug} is {busOtherBlogs.last_bus_departure_time}.
                        </MDBAccordionItem>

                        <MDBAccordionItem collapseId={7} headerTitle={`What payment options are available for ${fromSlug} to ${toSlug} bus ticket booking?`}>
                        {currentDomainname} offer various payment options for online bus ticket booking for {fromSlug} to {toSlug} like Wallet, Credit and debit cards, UPI payments such as Gpay, PhonePe, and Amazon Pay, wallets payments like Paytm and amazon pay, net banking, and buy now and pay later applications like Simpl.
                        </MDBAccordionItem>
                        
                        <MDBAccordionItem collapseId={8} headerTitle={`What is the ${fromSlug} to ${toSlug} bus timings?`}>
                        You can check the {fromSlug} to {toSlug} timing on the {currentDomainname} application; all you have to do is search for the {fromSlug}, {toSlug}, and travel date. Post that, you will be navigated to the bus operator list for {fromSlug} and {toSlug}. Select the preferred bus you want to travel to, and you can also see the first and last bus from the list.
                        </MDBAccordionItem>
                        
                        <MDBAccordionItem collapseId={9} headerTitle={`Can I reschedule my ${fromSlug} to ${toSlug} bus?`}>
                        If you opt for the Flexi ticket, you can easily reschedule your bus ticket from the {currentDomainname} application. You have to click on the contact us option and share details. You can call our customer care number to reschedule.
                        </MDBAccordionItem>

                        <MDBAccordionItem collapseId={10} headerTitle={`I missed my ${fromSlug} to ${toSlug} bus. Do I get a refund?`}>
                        {currentDomainname} provides a 100% refund if the bus is missed due to either {currentDomainname} or its partner company's fault. However, if the bus is missed due to any other reason not directly related to {currentDomainname}, no refund is provided.
                        </MDBAccordionItem>

                        <MDBAccordionItem collapseId={11} headerTitle={`Are buses running between ${fromSlug} to ${toSlug}?`}>
                        There is {busOtherBlogs.total_number_of_buses} Buses running between {fromSlug} to {toSlug} today.
                        </MDBAccordionItem>

                        <MDBAccordionItem collapseId={12} headerTitle={`What is the customer care number for ${fromSlug} to ${toSlug} bus?`}>
                        For any customer Support or Complaints regarding bus booking: Navigate to this Page https://{currentDomainname}/contact-us/, 24/7 Customer Support is available.
                        </MDBAccordionItem>

                        <MDBAccordionItem collapseId={13} headerTitle={`How can I cancel my ${fromSlug} to ${toSlug} bus ticket?`}>
                        Users can cancel {fromSlug} to {toSlug} bus tickets through their {currentDomainname} account online or in the app, or by contacting customer service. Cancellations will incur charges as outlined on the ticket.
                        </MDBAccordionItem>

                    </MDBAccordion>
                </div>
            </div>
            <div className='blog_book_bus_div mt-5'>
                <h2 className='mb-3'>Book {fromSlug} to {toSlug} Bus Online on {currentDomainname}</h2>
                <p>You can now book your {fromSlug} to {toSlug} bus easily with a simplified {currentDomainname} booking portal. Choose to customize your booking as per your type of bus and facilities available. You can make your booking either through the {currentDomainname} official website or app as per convenience and commerce your trip suitably.</p>
                <p>Cover your {fromSlug} to {toSlug} trip now on your choice of bus from available options like sleeper, seater, semi-sleeper, AC, non-AC, Volvo buses and more. Choose a bus to {toSlug} from {fromSlug} as per your timing and make quick booking. 
                </p>
                <p>Now a days online bus ticket booking is very popular among private bus ticket booking agents. Volvo bus ticket booking online is most common for the passengers to get hassle free bus ticket booking.</p>
            </div>
            <div className='blog_duration_div mt-4'>
                <h2 className='mb-3'>{fromSlug} to {toSlug} Time and Duration</h2>
                <p>Covering a distance of {busOtherBlogs.route_distance}, the {fromSlug} to {toSlug} journey takes around 9h 0m to complete. The total time taken can vary based on the route chosen, the operator you are traveling with and the total number of stoppages on the route. Travelers have an option to choose between private and state owned buses to book their tickets with.</p>
            </div>
            <div className='blog_bus_ticket_price_div mt-5'>
                <h2 className='mb-3'>{fromSlug} to {toSlug} Bus Ticket Price</h2>
                <p>The {fromSlug} to {toSlug} bus ticket prices are decently set to keep travel between the two cities affordable.You can book bus tickets online at lowest fare. The fare payable for these buses vary with the type of bus selected and the available amenities. You can book bus tickets between {fromSlug} and {toSlug} with the lowest bus fare starting from {busOtherBlogs.bus_fare}. Moreover, the first bus is available for {busOtherBlogs.bus_fare} and the last bus' fare is {busOtherBlogs.last_bus_fare}.</p>
            </div>
            <div className='blog_bus_time_div mt-5'>
                <h2 className='mb-3'>{fromSlug} to {toSlug} Bus Time</h2>
                <p>With buses running between {fromSlug} and {toSlug} frequently throughout the day, finding a suitable time for bus booking should not be a problem for you. The first bus for the day can be booked at {busOtherBlogs.first_bus_departure_time} and you can book the last bus any day by up to {busOtherBlogs.last_bus_departure_time}.</p>
            </div>
            <div className='blog_bus_with_div mt-5'>
                <h2 className='mb-3'>Booking {fromSlug} to {toSlug} Bus with {currentDomainname}</h2>
                <p>Booking your preferred bus service between {fromSlug} and {toSlug} is simplified with the help of the easy online portal that comes with convenient navigation. Passengers can book their buses as per their customization requirements. Go through user ratings and reviews from precious passengers before finalizing your bus service. For any queries regarding your trip, reach out to {currentDomainname}'s 24x7 customer care service.
                </p>
            </div>
            <div className='blog_bus_type_div mt-5'>
                <h2 className='mb-3'>{fromSlug} to {toSlug} Bus Types</h2>
                <p>Various types of buses serving passengers from {fromSlug} to {toSlug} ensuring safety and comfort. A few bus services are:</p>
                <ul>
                    <li>A/C Seater (2+2)</li>
                    <li>A/C Seater / Sleeper (2+2)</li>
                </ul>
            </div>
            {/* <div className='blog_about_from_to_city_div mt-5'>
                {
                    busOtherBlogs.about_source  &&
                        (
                            <>
                            <h2 className='mb-3'>About {fromSlug}</h2>
                            <div className="src" dangerouslySetInnerHTML={{ __html: decodeURIComponent(busOtherBlogs.about_source) }} />
                            </>
                        )
                }

                {
                    busOtherBlogs.about_destination  &&
                        (
                            <>
                            <h2 className='mb-3'>About {toSlug}</h2>
                            <div className="src" dangerouslySetInnerHTML={{ __html: decodeURIComponent(busOtherBlogs.about_destination) }} />
                            </>
                        )
                }
                
            </div> */}
            
            <div className='blog_top_bus_operator mt-5'>
                <div>
                    <h2 className='mb-3'>Bus from {toSlug} To {fromSlug}</h2>
                    <ul>
                        {
                            busOtherBlogs.bus_from_to && busOtherBlogs.bus_from_to.length > 0 &&
                            busOtherBlogs.bus_from_to?.slice(0, 10).map((bus, index) => {
                                return <li key={index}>{toSlug} to {fromSlug} {bus.bus_name} Bus</li>;
                            })
                        }
                        {
                            busOtherBlogs.bus_from_to && busOtherBlogs.bus_from_to.length > 10 &&
                            (
                                <li><a href="" className='more_cls'>{'More >>'}</a></li>
                            )
                        }
                    </ul>
                </div>
                <div>
                    <h2 className='mb-3'>Popular Routes From {toSlug}</h2>
                    <ul>
                        {
                            busOtherBlogs.popular_route_from_destination && busOtherBlogs.popular_route_from_destination.length > 0 &&
                            busOtherBlogs.popular_route_from_destination?.slice(0, 10).map((from, index) => {
                                return <li key={index}>{toSlug} to {from} Bus</li>;
                            })
                        }
                        {
                            busOtherBlogs.popular_route_from_destination && busOtherBlogs.popular_route_from_destination.length > 10 &&
                            (
                                <li><a href="" className='more_cls'>{'More >>'}</a></li>
                            )
                        }
                    </ul>
                </div>
                <div>
                    <h2 className='mb-3'>Popular Routes To {fromSlug}</h2>
                    <ul>
                        {
                            busOtherBlogs.popular_route_from_source && busOtherBlogs.popular_route_from_source.length > 0 &&
                            busOtherBlogs.popular_route_from_source?.slice(0, 10).map((from, index) => {
                                return <li key={index}>{from} to {fromSlug} Bus</li>;
                            })
                        }
                        {
                            busOtherBlogs.popular_route_from_source && busOtherBlogs.popular_route_from_source.length > 10 &&
                            (
                                <li><a href="" className='more_cls'>{'More >>'}</a></li>
                            )
                        }
                    </ul>
                </div>
                <div>
                    <h2 className='mb-3'>Top Bus Operators in {toSlug}</h2>
                    <ul>
                        {
                            busOtherBlogs.destnation_popular_sp && busOtherBlogs.destnation_popular_sp.length > 0 &&
                            busOtherBlogs.destnation_popular_sp?.slice(0, 10).map((sp, index) => {
                                return <li key={index}><a href="">{sp} Travels</a></li>;
                            })
                        }
                        
                        {
                            busOtherBlogs.destnation_popular_sp && busOtherBlogs.destnation_popular_sp.length > 10 &&
                            (
                                <li><a href="" className='more_cls'>{'More >>'}</a></li>
                            )
                        }
                    </ul>
                </div>
                <div>
                    <h2 className='mb-3'>Top Bus Operators in {fromSlug}</h2>
                    <ul>
                        {
                            busOtherBlogs.source_popular_sp && busOtherBlogs.source_popular_sp.length > 0 &&
                            busOtherBlogs.source_popular_sp?.slice(0, 10).map((sp, index) => {
                                return <li key={index}><a href="">{sp} Travels</a></li>;
                            })
                        }
                        
                        {
                            busOtherBlogs.source_popular_sp && busOtherBlogs.source_popular_sp.length > 10 &&
                            (
                                <li><a href="" className='more_cls'>{'More >>'}</a></li>
                            )
                        }
                    </ul>
                </div>
                <div>
                    <h2 className='mb-3'>Other Routes from {toSlug}</h2>
                    <ul>
                        {
                            busOtherBlogs.popular_route_from_destination && busOtherBlogs.popular_route_from_destination.length > 0 &&
                            busOtherBlogs.popular_route_from_destination?.reverse().slice(0, 10).map((from, index) => {
                                return <li key={index}>{toSlug} to {from} Bus</li>;
                            })
                        }
                        {
                            busOtherBlogs.popular_route_from_destination && busOtherBlogs.popular_route_from_destination.length > 10 &&
                            (
                                <li><a href="" className='more_cls'>{'More >>'}</a></li>
                            )
                        }
                    </ul>
                </div>
                <div>
                    <h2 className='mb-3'>Other Routes to {fromSlug}</h2>
                    <ul>
                        {
                            busOtherBlogs.popular_route_from_source && busOtherBlogs.popular_route_from_source.length > 0 &&
                            busOtherBlogs.popular_route_from_source?.reverse().slice(0, 10).map((from, index) => {
                                return <li key={index}>{from} to {fromSlug} to  Bus</li>;
                            })
                        }
                        {
                            busOtherBlogs.popular_route_from_source && busOtherBlogs.popular_route_from_source.length > 10 &&
                            (
                                <li><a href="" className='more_cls'>{'More >>'}</a></li>
                            )
                        }
                    </ul>
                </div>
                {/* <div>
                    <h2 className='mb-3'>Other Routes</h2>
                    <ul>
                        <li><a href="">New Laxmi Travels</a></li>
                        <li><a href="">New Laxmi Travels</a></li>
                        <li><a href="">New Laxmi Travels</a></li>
                        <li><a href="">New Laxmi Travels</a></li>
                        <li><a href="">New Laxmi Travels</a></li>
                        <li><a href="" className='more_cls'>{'More >>'}  </a></li>
                    </ul>
                </div> */}
                <div>
                    <h2 className='mb-3'>Train Routes</h2>
                    <ul>
                        {
                            busOtherBlogs.popular_route_from_destination && busOtherBlogs.popular_route_from_destination.length > 0 &&
                            busOtherBlogs.popular_route_from_destination?.slice(0, 10).map((from, index) => {
                                return <li key={index}>{fromSlug} to {from} Train</li>;
                            })
                        }
                        {
                            busOtherBlogs.popular_route_from_destination && busOtherBlogs.popular_route_from_destination.length > 10 &&
                            (
                                <li><a href="" className='more_cls'>{'More >>'}</a></li>
                            )
                        }
                    </ul>
                </div>
                <div>
                    <h2 className='mb-3'>Railway Information</h2>
                    <ul>
                        <li><a href="">Train Ticket Booking</a></li>
                        <li><a href="">Train Between Station</a></li>
                        <li><a href="">Train Seat Availability</a></li>
                        <li><a href="">PNR Status</a></li>
                        <li><a href="">Tatkal Ticket Booking</a></li>
                        <li><a href="">Premium Tatkal</a></li>
                        <li><a href="">Current Reservation</a></li>
                    </ul>
                </div>
                <div>
                    <h2 className='mb-3'>Taxi services/ Cab Services</h2>
                    <ul>
                        {
                            busOtherBlogs.popular_route_from_destination && busOtherBlogs.popular_route_from_destination.length > 0 &&
                            busOtherBlogs.popular_route_from_destination?.slice(0, 10).map((from, index) => {
                                return <li key={index}>{fromSlug} to {from} Taxi/Cab</li>;
                            })
                        }
                        {
                            busOtherBlogs.popular_route_from_destination && busOtherBlogs.popular_route_from_destination.length > 10 &&
                            (
                                <li><a href="" className='more_cls'>{'More >>'}</a></li>
                            )
                        }
                    </ul>
                </div>
                
            </div>
        </OtherBlogSectionStyle>
    </>
  )
}

const OtherBlogSectionStyle = styled.div`
    margin-top: 30px;
    font-family: inherit;
    h2{
        ${'' /* text-align : center; */}
        color: rgb(47, 49, 131);
        font-size: 22px;
    }
    
    .blog_faqs_div{
        .accordion-button{
            font-size: 17px;
            font-weight: 400;
            color: rgb(47, 49, 131);
            ${'' /* background-color: rgb(217, 57, 50); */}
        }
        
        .accordion-button:not(.collapsed){
            color: rgb(217, 57, 50);
            background-color: rgb(47, 49, 131);
            font-size: 17px;
            font-weight: 400;
        }
    }
    .blog_top_bus_operator{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 35px 20px;
        margin-bottom: 30px;
        h2{
            color: rgb(217, 57, 50);
            font-size: 20px;
        }
        ul{
            padding : 0px;
            li{
                list-style: none;
                padding: 3px 0px;
                font-weight: 400;

                color: gray;
                font-size: 14px;
                text-decoration: none;

                a{
                    color: gray;
                    font-size: 14px;
                    text-decoration: none;
                }
            }
            
        }
    }
    .more_cls{
        color: #2f3183 !important;
        font-size: 15px;
        font-weight: 500;
    }
    @media (min-width:250px) and (max-width:400px) {
        h2{
            font-size: 15px;
        }
        .blog_top_bus_operator{
            grid-template-columns: 1fr;
        }
    }
    @media (min-width:400px) and (max-width:700px) {
        h2{
            font-size: 17px;
        }
        .blog_top_bus_operator{
            grid-template-columns: 1fr;
        }
    }
    @media (min-width:700px) and (max-width:900px) {
        h2{
            font-size: 18px;
        }
        .blog_top_bus_operator{
            grid-template-columns: 1fr 1fr;
        }
    }
`;
export default OtherBlogs
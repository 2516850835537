import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const SeoContext = createContext();

const SeoContextProvider = (props) => {
    //that '/home' shoild be dynamic from each page
    const { endpoint } = props;

    const [seoData, setSeoData] = useState({
        title: '',
        description: '',
        keywords: '',
        canonical: ''
    });
    const currentHostname = window.location.hostname;
    useEffect(() => {
        const getSeoContent = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const apiKey = process.env.REACT_APP_API_KEY;
            const apiToken = process.env.REACT_APP_API_TOKEN;
            try {
                const response = await axios.get(apiUrl + `/master/seo/${currentHostname}/${endpoint}`, {
                headers: {
                    'YESBUS_API_KEY': apiKey,
                    Authorization: `Bearer ${apiToken}`,
                }
                });
                if (response.data.status === 200) {
                    setSeoData({
                        title: response.data.data.seo_title,
                        description: response.data.data.seo_description,
                        keywords: response.data.data.seo_keys,
                        canonical: response.data.data.seo_canonical,
                    });
                }
            } catch (error) {
                console.log(error);
            } finally {
                console.log('Content Fetched');
            }
        }
        getSeoContent();
    }, [endpoint]);
    return (
        <SeoContext.Provider value={{ seoData }}>
            <HelmetProvider>
                <Helmet>
                    <title>{seoData.title ? seoData.title : 'Buspartner|Online Bus Ticket Booking, Book AC Volvo Bus|Bhubaneswar, Odisha'}</title>
                    <meta name="description" content={seoData.description ? seoData.description : `buspartner.in is Odisha's largest online bus ticket booking platform offers bus travels, ac volvo bus at best rates. Easy bus ticket cancellation option & Instant refunds`} />
                    <meta name="Keywords" content={seoData.keywords ? seoData.keywords : 'online bus ticket booking, volvo ac bus booking in Odisha, bus ticket booking in Odisha, Bus tickets book, Booking of Bus'} />
                    {process.env.NODE_ENV === 'development' ? <meta name="robots" content="noindex, nofollow" /> : <meta name="robots" content="index, follow" />}
                    <link rel="canonical" href={seoData.canonical ? seoData.canonical : currentHostname} />
                    <meta name="theme-color" content="#2e307d" />
                </Helmet>
            </HelmetProvider>
            {props.children}
        </SeoContext.Provider>
    );
};

export default SeoContextProvider;

const FilterReducer = (state, action) => {
  //console.log(action)
  switch (action.type) {
   
    case "LOAD_BUSES":
      //console.log(action.payload);
      return {
        ...state,
        filter_buses: [...action.payload],
        all_buses: [...action.payload],
        total_buses: action.payload.length,
        loading: action.isload,
        errorIs: action.iseror,
        errormsg: action.errmsg
      };
      
    case "CLEAR_FILTERS":
      //console.log(action.payload);
      return {
        ...state,
        filter_buses: [...action.payload],
        total_buses: action.payload.length,
        loading: action.isload,
        errorIs: action.iseror,
        errormsg: action.errmsg,
        filters: {
          text: "",
          bustypesAry : []
        },
      };
    case "UPDATE_FILTERS_VALUE":
      const { name, value } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [name]: value,
        },
      };
    case "UPDATE_BUSBTYPE_VALUE":
      const { name1, isChecked } = action.payload;
      let updatedBustypesAry = [...state.filters.bustypesAry];
      if (isChecked) {
        updatedBustypesAry.push(name1); // Add name1 to the array
      } else {
        updatedBustypesAry = updatedBustypesAry.filter((name) => name !== name1); // Remove name1 from the array
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          bustypesAry: updatedBustypesAry,
        },
      };
    case "FILTER_BUSES":
      //console.log(action.isload,action.iseror,action.errmsg);
      let { all_buses } = state;
      let tempFilterProduct = [...all_buses];
      const { text, departure_filter, arrival_filter} = state.filters;
      if (text) {
        tempFilterProduct = tempFilterProduct.filter((curElem) => {
          return curElem.bus_name.toLowerCase().includes(text);
        });
      }
      if(departure_filter && departure_filter=='depart_before_6am'){
        tempFilterProduct = tempFilterProduct.filter((curElem) => {
          const departureTime = new Date(`1970-01-01T${curElem.departure_time}`);
          const sixAM = new Date(`1970-01-01T06:00:00`);
          return departureTime < sixAM;
        });
      }
      if (departure_filter && departure_filter=='depart_6am_to_12pm') {
        tempFilterProduct = tempFilterProduct.filter((curElem) => {
          const departureTime = new Date(`1970-01-01T${curElem.departure_time}`);
          const sixAM = new Date(`1970-01-01T06:00:00`);
          const twelvePM = new Date(`1970-01-01T12:00:00`);
          return departureTime >= sixAM && departureTime <= twelvePM;
        });
      }
      if (departure_filter && departure_filter=='depart_12pm_to_6pm') {
        tempFilterProduct = tempFilterProduct.filter((curElem) => {
          const departureTime = new Date(`1970-01-01T${curElem.departure_time}`);
          const twelvePM = new Date(`1970-01-01T12:00:00`);
          const sixPM = new Date(`1970-01-01T18:00:00`);
          return departureTime >= twelvePM && departureTime <= sixPM;
        });
      }
      if (departure_filter && departure_filter=='depart_after_6pm') {
        tempFilterProduct = tempFilterProduct.filter((curElem) => {
          const departureTime = new Date(`1970-01-01T${curElem.departure_time}`);
          const sixPM = new Date(`1970-01-01T18:00:00`);
          return departureTime > sixPM;
        });
      }
      if(arrival_filter && arrival_filter=='arrival_before_6pm'){
        tempFilterProduct = tempFilterProduct.filter((curElem) => {
          const arrivalTime = new Date(`1970-01-01T${curElem.arrival_time}`);
          const sixAM = new Date(`1970-01-01T06:00:00`);
          return arrivalTime < sixAM;
        });
      }
      if (arrival_filter && arrival_filter=='arrival_6am_to_12pm') {
        tempFilterProduct = tempFilterProduct.filter((curElem) => {
          const arrivalTime = new Date(`1970-01-01T${curElem.arrival_time}`);
          const sixAM = new Date(`1970-01-01T06:00:00`);
          const twelvePM = new Date(`1970-01-01T12:00:00`);
          return arrivalTime >= sixAM && arrivalTime <= twelvePM;
        });
      }
      if (arrival_filter && arrival_filter=='arrival_12pm_to_6pm') {
        tempFilterProduct = tempFilterProduct.filter((curElem) => {
          const arrivalTime = new Date(`1970-01-01T${curElem.arrival_time}`);
          const twelvePM = new Date(`1970-01-01T12:00:00`);
          const sixPM = new Date(`1970-01-01T18:00:00`);
          return arrivalTime >= twelvePM && arrivalTime <= sixPM;
        });
      }
      if (arrival_filter && arrival_filter=='arrival_after_6pm') {
        tempFilterProduct = tempFilterProduct.filter((curElem) => {
          const arrivalTime = new Date(`1970-01-01T${curElem.arrival_time}`);
          const sixPM = new Date(`1970-01-01T18:00:00`);
          return arrivalTime > sixPM;
        });
      }
      if (state.filters.bustypesAry.length > 0) {
        tempFilterProduct = tempFilterProduct.filter((curElem) => {
          const busTypeNamesArray = curElem.bustypenames.split(' / '); // Split by comma and space
          return busTypeNamesArray.some(type => state.filters.bustypesAry.includes(type));
        });
      }
      return {
        ...state,
        loading: action.isload,
        errorIs: action.iseror,
        errormsg: action.errmsg,
        filter_buses: tempFilterProduct,
      };
    default:
      return state;
  }
};
export default FilterReducer;
  
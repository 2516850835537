import React from 'react'
import styled from "styled-components";
import SearchFormMob from './SearchFormMob';
import SearchFormDesk from './SearchFormDesk';
const HomeBanner = () => {
    return (
        <>
            <HomeBannerSection className="home_banner_section">
                <div className="ssp-banner desk_mode">
                    <div id="clouds">
                        {/* <div className="cloud x1"></div>
                        <div className="cloud x2"></div>
                        <div className="cloud x3"></div> */}
                        {/* <div className="star1"></div>
                        <div className="star2"></div>
                        <div className="star3"></div> */}
                        <div className="yb_search_form">
                            <div className="bus_run_animation">
                                <img src="/images/animated-bus-image-0029.gif" alt="" />
                            </div>
                            <div className="banner_label">
                                <h1>Stop Looking, Start Booking</h1>
                                <h2>Your destination partner! Connecting people & places.</h2>
                                <div id="search_form_desk">
                                    <SearchFormDesk/>
                                </div>
                            </div>
                            <div>
                                <img src="/images/j0173965.gif" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ssp-banner mob_mode">
                    <div id="clouds">
                        {/* <div className="star1"></div>
                        <div className="star2"></div>
                        <div className="star3"></div> */}
                        <div className="yb_search_form">
                            <div className="banner_label">
                                <h1>Stop Looking, Start Booking</h1>
                                <h2>Your destination partner! Connecting people & places.</h2>
                                <div id="search_form_mob">
                                    <div className="mobile-white-box *shadow-sm">
                                        <SearchFormMob/>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HomeBannerSection>
        </>
    )
}
const HomeBannerSection = styled.section`
    ${'' /* background:radial-gradient(215% 135% at top center,#1B2947 10%,#75517D 30%,#132049 55%,#fff) no-repeat; */}
    background:radial-gradient(215% 135% at top center,rgb(59 62 135) 10%,rgb(123 124 178 / 76%) 30%,rgb(59 62 135) 55%,#fff) no-repeat;
    position: relative;
    width: 100%;
    height: 525px;

    .banner_label h1{
        font-size: 1.5em;
        margin: 0px 0px 30px 0px;
    }
    .banner_label h2{
        font-size: 1.5em;
        margin: 0px 0px 25px 0px;
    }
    .yb_search_form{
        position: absolute;
        display: flex;
        /* width: 100%; */
        align-items: flex-start;
        justify-content: space-between;
        top: 105px;
        .bus_run_animation{
            flex-basis: 30%;
            top: 90px;
        }
    }
    .input-group-prepend{
        display:flex;
    }

    /* Styles for screens smaller than 768px */
    @media (max-width: 767px) {
    /* Add your styles here */
        height: 430px;
        .yb_search_form{
            width: 100%;
            top: 30px;
            lign-items: center;
            justify-content: center;
        }
        .banner_label h1{
            font-size: 18px;
            margin: 0px 0px 14px;
        }
        .banner_label h2 {
            font-size: 14px;
            margin: 0px 0px 12px;
            padding: 0px 13px;
        }
        .mobile-white-box {
            
            padding: 10px;
            border-radius: 5px;
            margin-top: 18px;
        }
        .shadow-sm {
            ${'' /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important; */}
        }
        
        .mobile-white-box .img1 {
            margin-top: 47px;
            margin-left: 13px;
        }
        .mobile-white-box .img2 {
            margin-left: 13.5px;
            height: 60px;
        }
        .mobile-white-box .img3 {
            margin-left: 13px;
            margin-top: -20px;
        }
        .mobile-white-box .img4 {
            margin-top: 70px;
        }
        
        .mobile-white-box .form-group {
            margin-bottom: 5px;
            width: 100%;
            div{
                position: relative;
                display: block !important;
                div{
                    position: relative;
                    display: block !important;
                    z-index: 9999;
                }
            }
        }
        ${'' /* .mobile-white-box .border-bottom {
            border-bottom: 1px solid #b1b1b1!important;
        } */}
        .mobile-white-box .form-group label {
            color: #fff;
            font-weight: 700;
        }
        .mobile-white-box .form-group input {
            color: #072c6b;
            width: 100%;
            border: none;
            
            padding: 5px 10px;
            border-radius: 4px;
            z-index: 99;
            position: relative;
        }
        .mobile-white-box .form-group {
            margin-bottom: 5px;
        }
        
        .mob-dt-big, .mob-dt-sm {
            text-align: center;
            font-weight: 600;
        }
        .mob-dt-big {
            border: 2px solid rgb(217 57 50);
            border-radius: 4px;
            padding: 4px;
            font-size: 12px;
            color: #fff;
            line-height: 17px;
            box-shadow: rgb(0 0 0 / 55%) 0px 3px 23px;
            width: 60%;
            margin: auto;
            input.calendar{
                background: none;
                border: none;
                height: 22px;
                padding: 0px;
                text-align: center;
                color: #fff;
                font-weight: 600;
            }
            input.calendar:focus{
                outline: 0;
                border: none;
                box-shadow: none;
            }
        }
        .mob-bus_search {
            width: 100%;
            margin: 0 auto;
        }
        .mob-bus_search .mob-bt {
            display: inline-block;
            margin-left: 5px;
            font-weight: 600;
            text-transform: uppercase;
            position: relative;
            background: #d93932;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            color: #fff;
            border-radius: 5px;
            padding: 7px 25px;
            font-size: 14px;
            transition: all 0.5s ease-in-out 0s;
            border: 1.5px solid #d93932;
            box-shadow: rgb(0 0 0 / 69%) 0px 2px 16px;
        }
        .btn:not(:disabled):not(.disabled) {
            cursor: pointer;
        }
        .row{
            width:100%;
            margin: 0px;
        }

    }

    /* Styles for screens between 768px and 991px */
    @media (min-width: 768px) and (max-width: 991px) {
    /* Add your styles here */
    .yb_search_form{
            width: 100%;
            top: 90px;
            lign-items: center;
            justify-content: center;
        }
        .banner_label h1{
            font-size: 30px;
            margin: 0px 0px 14px;
        }
        .banner_label h2 {
            font-size: 25px;
            margin: 0px 0px 12px;
            padding: 0px 13px;
        }
        .mobile-white-box {
            
            padding: 10px;
            border-radius: 5px;
            margin-top: 18px;
        }
        .shadow-sm {
            ${'' /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important; */}
        }
        
        .mobile-white-box .img1 {
            margin-top: 47px;
            margin-left: 13px;
        }
        .mobile-white-box .img2 {
            margin-left: 13.5px;
            height: 60px;
        }
        .mobile-white-box .img3 {
            margin-left: 13px;
            margin-top: -20px;
        }
        .mobile-white-box .img4 {
            margin-top: 70px;
        }
        
        .mobile-white-box .form-group {
            margin-bottom: 5px;
            width: 100%;
            div{
                position: relative;
                display: block !important;
                div{
                    position: relative;
                    display: block !important;
                    z-index: 9999;
                }
            }
        }
        ${'' /* .mobile-white-box .border-bottom {
            border-bottom: 1px solid #b1b1b1!important;
        } */}
        .mobile-white-box .form-group label {
            color: #fff;
            font-weight: 700;
        }
        .mobile-white-box .form-group input {
            color: #072c6b;
            width: 100%;
            border: none;
            
            padding: 5px 10px;
            border-radius: 4px;
            z-index: 99;
            position: relative;
        }
        .mobile-white-box .form-group {
            margin-bottom: 5px;
        }
        
        .mob-dt-big, .mob-dt-sm {
            text-align: center;
            font-weight: 600;
        }
        .mob-dt-big {
            border: 2px solid rgb(217 57 50);
            border-radius: 4px;
            padding: 4px;
            font-size: 12px;
            color: #fff;
            line-height: 17px;
            box-shadow: rgb(0 0 0 / 55%) 0px 3px 23px;
            width: 60%;
            margin: auto;
        }
        .mob-bus_search {
            width: 100%;
            margin: 0 auto;
        }
        .mob-bus_search .mob-bt {
            display: inline-block;
            margin-left: 5px;
            font-weight: 600;
            text-transform: uppercase;
            position: relative;
            background: #d93932;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            color: #fff;
            border-radius: 5px;
            padding: 7px 25px;
            font-size: 14px;
            transition: all 0.5s ease-in-out 0s;
            border: 1.5px solid #d93932;
            box-shadow: rgb(0 0 0 / 69%) 0px 2px 16px;
        }
        .btn:not(:disabled):not(.disabled) {
            cursor: pointer;
        }
        .row{
            width:100%;
            margin: 0px;
        }
    }

    /* Styles for screens larger than 992px */
    @media (min-width: 992px) {
    /* Add your styles here */
        
    }
    @media (min-width:250px) and (max-width:390px) {
        .yb_search_form{
            top: 20px;
            padding: 0px 10px;
        }
        .banner_label h2{
            margin: 0px 0px 0px;
        }
        .shadow-sm{
            margin-top: 0px;
        }
    }
`;

export default HomeBanner